.full-growth-height {
  display: flex;
  flex-direction: column;
}

.bridge-page {
  font-family: 'Poppins', sans-serif;
  height: calc(100vh - 20px);
  .bridge-header {
    &__header-1 {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 2.25rem;
      line-height: 48px;
      color: #34404b;
      @media (max-width: 768px) {
        display: none;
      }
    }
    &__header-2 {
      display: none;
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-top: 1rem;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .bridge-header-icon {
    margin-right: 0.75rem;
  }
  .bridge-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    margin: 3rem 0rem;
    .bridge-secondary-box {
      width: 25%;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 24px;
      margin-right: 1rem;
      .title {
        font-size: 1.5rem;
        font-weight: 600;
        color: $colorNeutral900;
        padding: 1rem;
        background-color: $colorNeutral200;
        border-radius: 24px 24px 0 0;
      }
      ul {
        flex-grow: 1;
        margin: 24px 0;
        padding-left: 2rem;
        padding-right: 1rem;
        li {
          margin-bottom: 1rem;
        }
      }
    }
    .bridge-main-box {
      border-radius: 24px;
      width: 75%;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      .progressbar {
        text-align: center;
        position: relative;
        counter-reset: step;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 1rem 1rem 0 1rem;
        color: $colorNeutral500;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #ddd;
          top: 15px;
          z-index: -1;
        }
        li {
          list-style: none;
          &:before {
            content: counter(step);
            counter-increment: step;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border: 2px solid #ddd;
            display: block;
            text-align: center;
            border-radius: 50%;
            margin: 0 auto 10px auto;
            background-color: white;
          }
          &.active {
            color: $colorPrimary400;
          }
          &.active:before {
            border-color: $colorPrimary400;
          }
        }
      }
      .main-content {
        margin: 2rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        &__item {
          display: flex;
          flex-direction: column;
          margin: 1rem 0;
        }
        &__item:last-child {
          justify-content: flex-end;
          flex-direction: row;
        }
        // .wrap-bridge-btn {
        //   flex-grow: 1;
        //   display: flex;
        //   justify-content: flex-end;
        // }
        .bridge-btn {
          // display: inline-block;
          // align-self: flex-end;
          margin-left: 1rem;
          padding: 10px 42px;
          background: $colorPrimary400;
          color: #ffffff;
          font-family: inherit;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25rem;
          border-radius: 4px;
          border: none;
          &:hover {
            cursor: pointer;
          }
          &--disabled {
            background-color: $colorNeutral700;
            pointer-events: none;
          }
        }
      }
      .swap-rifi {
        color: $colorNeutral900;
        .address-receive {
          &__item {
            margin: 0.5rem 0;
          }
          input {
            padding: 15px 15px 15px 20px;
            border-radius: 16px;
            border: none;
            background-color: $colorNeutral200;
          }
          input:focus {
            outline: none;
          }
        }
        .confirm {
          &__item {
            margin: 0.5rem 0;
          }
          &__text {
            cursor: pointer;
          }
          input {
            margin-right: 0.5rem;
          }
        }
        .amount-swap {
          &__item {
            display: flex;
            justify-content: space-between;
            margin: 0.5rem 0;
          }
          &__enter {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-radius: 15px;
            background-color: $colorNeutral200;
          }
          input {
            width: 80%;
            border: none;
            border-radius: 15px;
            background-color: $colorNeutral200;
            padding-left: 15px;
          }
          input:focus {
            outline: none;
          }
          &__btn-max {
            padding: 8px 12px;
            background: #dfe7fd;
            color: $colorPrimary;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.25rem;
            border-radius: 8px;
            font-family: inherit;
            border: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
        &__warning {
          font-size: 0.8rem;
          color: $colorRedApp;
          display: none;
          &--active {
            display: inline-block !important;
          }
        }
      }
      .verify-transaction {
        .transaction-info {
          display: flex;
          flex-direction: row;
          padding: 0.5rem 0;
          &__label {
            margin-right: 0.5rem;
          }
          &__value {
            font-weight: bold;
          }
        }
        .spinner-border {
          display: flex;
          justify-content: center;
        }
      }
      .receive-rifi {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__item {
          margin: 0.5rem;
        }
        h1 {
          color: $colorPrimary400;
        }
      }
    }

    .bridge-btn-history {
      margin: 20px 0;
      cursor: pointer;
      text-align: left;
      padding: 1rem;
      span {
        font-family: Roboto;
        font-weight: 500;
        font-size: 16px;
        padding: 8px 12px;
        border: 1px solid #2963f9;
        color: #2963f9;
        border-radius: 7px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .bridge-page {
    .bridge-box {
      .bridge-secondary-box {
        width: 30%;
      }
      .bridge-main-box {
        width: 70%;
      }
    }
  }
}

@media (max-width: 768px) {
  .bridge-page {
    .bridge-box {
      .bridge-secondary-box {
        display: none;
      }
      .bridge-main-box {
        min-width: 90vw;
      }
    }
  }
}

@media (max-width: 576px) {
  .bridge-page {
    .bridge-box {
      .bridge-main-box {
        .verify-transaction {
          .main-content__item:last-child {
            flex-direction: column;
            .bridge-btn {
              margin: 0.5rem 3rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .bridge-page {
    .bridge-box {
      .bridge-main-box {
        input::placeholder {
          color: transparent;
        }
      }
    }
  }
}
