.spinner {
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.coin-view {
  font-family: Poppins;

  .breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 16px 0;

    &-item {
      a {
        color: $colorNeutral600;
      }
    }
    .active {
      color: $colorNeutral900;
      font-weight: bold;
    }

    &-1 {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &-2 {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }

  .section {
    &-line {
      margin: 40px 0 0px 0;
      padding: 0 15px;
      border-top: 1px solid $colorNeutral300;
    }

    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $colorNeutral900;
      margin: 48px 0 24px 0;
    }

    &-1 {
      display: flex;
      flex-wrap: wrap;

      margin-top: 60px;

      @media (max-width: 768px) {
        display: block;
        margin-top: 16px;
      }

      & > div {
        &:nth-of-type(1) {
          flex: 0 0 36%;

          @media (max-width: 1376px) {
            flex: 0 0 100%;
          }
        }

        &:nth-of-type(2) {
          order: 3;
          flex: 0 0 36%;
          @media (max-width: 1376px) {
            order: 2;
            flex: 0 0 100%;
            padding-top: 15px;
          }

          @media (max-width: 768px) {
            padding-top: 8px;
          }
        }

        &:nth-of-type(3) {
          flex: 0 0 64%;
          @media (max-width: 1376px) {
            order: 3;
            flex: 0 0 100%;
            padding: 10px 0;
          }
        }

        &:nth-of-type(4) {
          flex: 1 0 100%;
          order: 4;
        }
      }
    }

    &-3 {
      @media (max-width: 768px) {
        display: block;
      }

      .col {
        &:nth-of-type(1) {
          order: 2;
        }

        &:nth-of-type(2) {
          margin-bottom: 40px;
        }
      }
    }
  }

  .coin {
    &-name {
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;

      margin: 0px 16px;

      color: $colorText;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-logo {
      @media (max-width: 768px) {
        width: 56px;
      }
    }

    &-links {
      &-open {
        display: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $colorPrimary;
        cursor: pointer;

        @media (max-width: 768px) {
          display: block;
        }

        &:after {
          content: '';
          border: solid $colorPrimary;
          border-width: 0 2px 2px 0;
          padding: 4px;
          margin-left: 12px;
          display: inline-block;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }

      &-section {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $colorNeutral900;
      }

      &-group {
        .lib-link {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          background: none;

          display: block;
          padding-left: 0;

          &:first-of-type {
            padding-top: 20px;
          }

          &:last-of-type {
            border-bottom: 1px solid $colorNeutral300;
            padding-bottom: 28px;
            margin-bottom: 16px;
          }
        }
      }
    }

    &-status {
      display: flex;

      margin: 19px 0px;

      div {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        background: $colorNeutral200;

        padding: 8px 12px;
        margin: 0px 12px 0 0;

        border-radius: 4px;
      }
    }

    &-info {
      &-urls {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          display: none;
        }

        > a,
        > div {
          margin: 0px 12px 12px 0px;
        }
      }

      &-contract {
        margin: 1.7rem 0px;
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;

        @media (max-width: 768px) {
          margin-top: 0;
        }

        p {
          margin-bottom: 0.5rem;
        }

        div {
          display: inline-block;
          background: $colorNeutral200;
          padding: 8px 12px;

          border-radius: 4px;
          border: none;
        }

        &-copy {
          margin: 0px 0px 3px 10px;
          cursor: pointer;

          path {
            fill: $colorNeutral900;
          }
        }
      }
    }

    &-price {
      @media (max-width: 768px) {
        // display: flex;
        // justify-content: space-between;
        margin-bottom: 12px;
      }

      &-name {
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $colorNeutral700;

        margin: 9px 0px;
      }

      &-number {
        font-family: Poppins;
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: $colorNeutral900;

        margin: 0px;
      }

      &-change {
        margin: 0px 12px;

        div {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: white;

          border-radius: 4px;
          padding: 8px;
        }

        .up {
          background: $colorSuccess400;
        }

        .down {
          background: $colorError400;
        }
      }
    }

    &-equivalent {
      display: flex;
      align-items: center;
      margin-top: 7px;

      @media (max-width: 768px) {
        // display: none;
      }

      &-number {
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: $colorNeutral900;

        margin: 0px;
      }

      &-change {
        margin: 0px 8px;

        div {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        .up {
          color: $colorSuccess400;
        }

        .down {
          color: $colorError400;
        }
      }
    }

    &-progress {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      margin-top: 12px;

      @media (max-width: 768px) {
        font-size: 14px;
        justify-content: space-between;
      }

      &-state {
        color: $colorNeutral900;
        margin: 0px 5px 0px 0px;
        white-space: nowrap;
      }

      .progress {
        width: 250px;
        height: 12px;
        margin: 0px 10px;
        border-radius: 40px;
        background: $colorNeutral300;
        overflow: hidden;

        &-bar {
          background: $colorSecondaryBorrow;
        }

        @media (max-width: 768px) {
          order: 10;
          margin: 0;
          flex-basis: 100%;
        }
      }

      .dropdown {
        position: relative;
        display: inline-block;
        margin-left: 5px;
      }

      .dropbtn {
        padding: 8px 12px;
        margin: 0px 5px;

        font-size: 14px;
        line-height: 16px;
        color: $colorNeutral900;
        background: $colorNeutral200;

        border: none;
        border-radius: 4px;

        @media (max-width: 768px) {
          margin: 0 0 8px 0;
        }

        &:focus {
          box-shadow: none;

          &:active {
            box-shadow: none;
            background: $colorPrimary;
          }
        }
      }

      .droparrow {
        width: 0;
        height: 0;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;

        display: inline-block;
        margin-bottom: 2px;
        margin-left: 5px;
      }

      .dropdown-content {
        position: absolute;
        top: calc(100% + 6px) !important;
        left: 50% !important;
        transform: translate(-50%, 0px) !important;
        background-color: white;
        min-width: 120px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        border: none;
        border-radius: 5px;

        @media (max-width: 768px) {
          top: calc(100%) !important;
          left: 0 !important;
        }

        &:before {
          content: '';
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid white;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translate(-50%, 0px);

          @media (max-width: 768px) {
            left: 75%;
          }
        }
        a {
          font-family: Roboto;
          font-size: 14px;
          color: black;
          font-weight: bold;
          line-height: 1.5;
          padding: 6px 12px;
          display: block;

          &:hover {
            background-color: #e8e8f5;
            cursor: pointer;
          }
        }
      }
    }

    &-market {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin-top: 6px;

      @media (max-width: 768px) {
        display: block;
        margin-top: 0.5rem;
      }

      .hide {
        @media (max-width: 768px) {
          display: none;
        }
      }

      &-block {
        padding: 0px 25px;
        margin-top: 24px;
        border-right: 1px solid $colorNeutral300;
        flex-grow: 1;

        &:nth-of-type(1) {
          padding-left: 0px;
          grid-column: span 3;

          @media (max-width: 1376px) {
            grid-column: span 4;
          }

          @media (max-width: 768px) {
            margin-top: 24px;
          }
        }

        &:nth-of-type(2) {
          grid-column: span 3;
          @media (max-width: 1376px) {
            grid-column: span 4;
          }
        }

        &:nth-of-type(3) {
          grid-column: span 3;
          @media (max-width: 1376px) {
            grid-column: span 4;
            border-right: none;
          }
        }

        &:nth-of-type(4) {
          grid-column: span 3;

          padding-right: 0px;
          border-right: none;

          @media (max-width: 1376px) {
            grid-column: span 12;
            padding-left: 0;
          }

          @media (max-width: 768px) {
            margin-bottom: 12px;
          }
        }

        @media (max-width: 768px) {
          padding: 0px;
          border-right: none;
          border-bottom: 1px solid $colorNeutral300;
        }

        &-stat {
          display: block;
          @media (max-width: 768px) {
            display: flex;
            justify-content: space-between;
          }

          & > div:first-of-type {
            display: flex;
            flex-wrap: wrap;
          }

          &-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $colorNeutral700;
            white-space: nowrap;

            @media (max-width: 768px) {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
            }
          }

          &-right {
            display: block;
          }

          &-number {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;

            margin-bottom: 12px;

            @media (max-width: 768px) {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;

              margin-bottom: 16px;
              text-align: right;
            }
          }

          &-change {
            div {
              margin-bottom: 56px;

              @media (max-width: 768px) {
                margin-left: 8px;
                margin-bottom: 20px;
                text-align: end;
              }
            }
          }

          &-percent {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            @media (max-width: 768px) {
              margin-left: 8px;
            }
          }
        }
      }

      &-btn {
        display: none;

        @media (max-width: 768px) {
          display: block;
        }

        button {
          width: 100%;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: white;
          text-align: left;

          background: radial-gradient(
              392.46% 392.46% at -24.46% -293.85%,
              #84ff82 0%,
              rgba(27, 118, 255, 0) 100%
            ),
            #2d379b;
          border-radius: 8px;
          border: none;
          margin: 16px auto 0 auto;
          padding: 24px 16px;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-top: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: translate(0, -50%) rotate(225deg);

            position: absolute;
            right: 32px;
            top: 50%;
          }

          &.show {
            &::after {
              transform: translate(0, -50%) rotate(45deg);
            }
          }
        }
      }
    }

    &-chart {
      width: 100%;
      background-color: transparent;

      &-name {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;

        margin: 48px 0 24px 0;
      }

      &-tabs {
        margin-bottom: 24px;
        .lib-tabs-tab {
          padding: 8px 24px;

          @media (max-width: 768px) {
            padding: 6px 18px;
          }
        }
      }
    }

    &-details {
      &-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;

        color: $colorNeutral900;

        margin: 48px 0px 24px 0px;
      }

      &-desc {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $colorNeutral800;

        margin: 3px 0px;
      }
    }

    &-stats {
      padding: 24px;
      margin-bottom: 40px;

      background: white;
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.18);
      border-radius: 24px;

      @media (max-width: 768px) {
        box-shadow: none;
        padding: 4px 0 0 0;
      }

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 48px 0px 0px 0px;

        &:first-of-type {
          margin: 0;
        }

        &:last-of-type {
          margin-bottom: 10px;
        }
      }

      &-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $colorNeutral700;

        margin: 0;

        @media (max-width: 768px) {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
      }

      &-value {
        font-weight: bold;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $colorNeutral900;

        margin: 0;

        @media (max-width: 768px) {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        }

        &-change {
          text-align: end;

          div {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }

          .up {
            color: $colorSuccess400;
          }

          .down {
            color: $colorError400;
          }
        }
      }

      &-btn {
        display: block;
        width: 180px;
        height: 52px;

        font-weight: bold;
        font-size: 18px;
        line-height: 48px;
        color: $colorNeutral900;

        background: $colorNeutral200;
        border-radius: 4px;
        border: none;
        margin: 29px auto;
        padding: 2px 20px;

        &:hover {
          background: $colorNeutral300;
        }
      }
    }

    &-analytics {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      overflow-x: auto;
      margin-bottom: 20px;
      box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
        0px 10px 32px -4px rgba(15, 40, 61, 0.1);

      &-big {
        display: unset;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &-small {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }

      &-name {
        display: flex;
        align-items: center;
      }

      table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        min-width: 1090px;
        margin: 0 auto;
      }

      tr {
        th {
          &:first-of-type {
            background: white;
            position: sticky;
            left: 0;
          }
        }

        &:nth-of-type(even) {
          background-color: #f9f9fb;
          td {
            &:first-of-type {
              background: #efeff2;
            }
          }
        }

        &:nth-of-type(odd) {
          td {
            &:first-of-type {
              background: #f5f5f6;
            }
          }
        }
      }

      th,
      td {
        padding: 32px 0;
        min-width: 240px;
      }

      &-top {
        display: flex;
        justify-content: space-between;
        padding: 36px 24px 20px 24px;
        position: sticky;
        left: 0;

        &-rank {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $colorPrimary;
          margin-right: 10px;

          @media (max-width: 768px) {
            font-size: 14px;
          }

          span {
            font-family: Roboto;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            color: $colorNeutral900;

            @media (max-width: 768px) {
              font-size: 20px;
            }
          }
        }
      }

      &-title {
        margin-bottom: 24px;
      }

      &-row {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
      }

      &-table {
        &-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $colorPrimary;
          text-align: start;

          margin: 3px auto;
          padding-left: 32px !important;
          position: sticky;
          left: 0;
        }

        &-header {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: $colorPrimary;
          margin: 0px 29px 0 0;
          text-align: center;
        }

        &-data {
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $colorNeutral900;
          margin: 0px 0 0 29px;
          text-align: center;
        }
      }

      &-measures {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
      }

      &-dropdown {
        position: relative;
        display: inline-block;
      }

      #coin-analytics-dropdown {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $colorWhite;
        background: radial-gradient(
            392.46% 392.46% at -24.46% -293.85%,
            #84ff82 0%,
            rgba(27, 118, 255, 0) 100%
          ),
          #2d379b;
        padding: 14px 12px;

        border-radius: 4px;
        border: none;
        min-width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:focus {
          box-shadow: none;
        }
      }

      // &-droparrow {
      //   width: 0;
      //   height: 0;

      //   border-left: 5px solid transparent;
      //   border-right: 5px solid transparent;
      //   border-top: 5px solid $colorWhite;

      //   display: inline-block;
      //   margin-bottom: 2px;
      // }

      .dropdown-menu {
        background-color: $colorNeutral200;
        // width: 100%;
        min-width: unset;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
        z-index: 1;
        border: none;
        border-radius: 5px;
        padding: 10px;
        // right: 0 !important;
        // left: auto !important;

        &.show {
          display: block;
        }

        div {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $colorNeutral600;
          padding: 14px 0px 14px 8px;
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background-color: #e8e8f5;
          }
        }
      }

      &-block {
        display: flex;
        justify-content: space-between;

        &:nth-of-type(odd) {
          background-color: #f9f9fb;
        }
      }

      &-name {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $colorPrimary;
        padding: 20px 0 20px 18px;
      }

      &-header {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $colorPrimary;
        background: rgba(50, 49, 80, 0.05);
        padding: 36px 0 36px 36px;
        min-width: 140px;
      }

      &-data {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $colorNeutral900;
        text-align: center;
        margin: auto 0;
      }

      &::-webkit-scrollbar {
        @extend %scrollbar;
      }

      &::-webkit-scrollbar-thumb {
        @extend %scrollbar-thumb;
      }
    }
  }
}
