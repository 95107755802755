.price-page {
  min-height: calc(100vh - 24px);
  position: relative;
  .status-bar {
    @media (max-width: 1024px) {
      .title-page {
        display: none !important;
      }
    }
  }
  .fallback {
    font-size: 2rem;
    font-weight: 500;
    color: $colorNeutral700;
    position: absolute;
    top: 50%;
    right: 50%;
  }
  &__title {
    visibility: hidden;
    display: flex;
    align-items: flex-end;
    margin: 2px 0;
    .price-page-title {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $colorTextBlack;
    }
    .price-icon-title {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    @media (max-width: 1024px) {
      visibility: visible;
      margin: 32px 0;
    }
  }
  .price {
    // padding-top: 40px;
    &__search {
      padding-bottom: 20px;
      .lib-search {
        height: 56px;
      }
    }
  }

  @media (max-width: 576px) {
    &__title {
      margin: 22px 0;
    }

    .price {
      // padding-top: 40px;
      &__search {
        margin: 0 -8px;
        padding: 10px 8px;
        box-shadow: 0px 4px 6px -4px rgba(15, 40, 61, 0.12),
          0px 8px 8px -4px rgba(15, 40, 61, 0.08);
        .lib-search {
          height: 48px;
        }
      }
      &__cards {
        margin-top: 12px;
        .price-cards {
          margin-left: -8px;
          margin-right: -8px;
          .col-card {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }
  }
}

.price-card {
  background-color: $colorTrTable;
  border: 1px solid $colorNeutral300;
  border-radius: 12px;
  padding: 24px;
  margin: 12px 0px;
  font-family: 'Poppins';
  min-height: 219px;
  $p: &;
  &__header {
    display: flex;
    justify-self: center;
    align-items: center;
    border-bottom: 1px solid $colorNeutral300;
    padding-bottom: 20px;
    #{$p}__icon {
      width: 36px;
      height: 36px;
      margin-right: 20px;
      min-width: 36px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    #{$p}__name {
      font-family: 'Poppins';
      font-size: 1.23rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
  &__body {
    padding-top: 20px;
    #{$p}__des {
      font-family: inherit;
      color: $colorNeutral700;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.25rem;
    }
    #{$p}__price {
      margin: 8px 0px;
      font-family: 'Roboto';
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.75rem;
      color: $colorNeutral900;
    }
    #{$p}__percent {
      display: flex;
      vertical-align: middle;

      font-family: inherit;
      line-height: 1.25rem;
      font-size: 1rem;
      font-weight: 500;
      .up {
        color: $colorSuccess400;
      }
      .down {
        color: $colorError400;
      }
    }
    #{$p}__time {
      margin: 8px 0;
      color: $colorNeutral500;
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1rem;
    }
  }
}
