.switch-network-modal {
  color: $colorThLiquidate;
  .modal-header {
    padding: 20px 20px;
    .title-modal {
      text-align: center;
      font-weight: 600;
      font-size: 1.25rem;
    }
    .close-btn {
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 0px 20px 20px 20px;
    .body-content {
      border-bottom: 1px solid $colorNeutral300;
      padding-bottom: 30px;
    }
    .title-body {
      text-align: center;
      font-weight: 400;
      padding-bottom: 1.5rem;
      color: $colorError400;
      font-size: 1.2rem;
    }
    .description-body {
      text-align: center;
      padding-bottom: 1.5rem;
      .text-bold {
        font-weight: bold;
        font-size: 1.6rem;
      }
      .text-nomal {
        font-size: 1rem;
        font-weight: 400;
      }
    }
    .network-item {
      padding: 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $colorNeutral600;
      border-radius: 40px;
      margin-bottom: 10px;
      cursor: pointer;
      &.active {
        border-color: $colorLogo;
        background: $colorNeutral300;
      }
      .network-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .network-text {
        font-weight: bold;
      }
    }
    .btn-box {
      display: flex;
      width: 100%;
      padding-top: 40px;
      .btn {
        margin: 0px 10px;
        width: 50%;
        border: none;
        font-weight: 600;
        padding: 15px 0px;
        border-radius: 8px;
        box-shadow: none;
      }
      .btn-cancel {
        background: $colorNeutral300;
        color: $colorNeutral500;
      }
      .btn-switch {
        background: $colorPrimary400;
        color: $colorWhite;
        &:hover {
          background: darken($color: $colorPrimary400, $amount: 5);
        }
        &:focus {
          background: $colorPrimary400;
        }
        &:active {
          background: $colorPrimary400;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
