.vault-header {
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  &__item {
    padding-right: 14px;
  }
  &__title {
    .vault-link {
      color: $colorNeutral600;
    }
    .vault-link:hover {
      color: $colorPrimary;
    }
  }
  &__coin {
    color: $colorText;
  }
  &--small-screen {
    display: none !important;
    margin-top: 24px;
  }
}

.vault-detail {
  &__coin-info {
    display: flex;
    margin-top: 48px;
    margin-bottom: 56px;
    align-items: center;
    justify-content: flex-start;
    .vault-pool-icon {
      .icon {
        width: 50px;
        height: 50px;
      }
    }
    .coin-info-name {
      font-family: 'Poppins';
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 3rem;
      color: $colorText;
      padding-left: 10px;
    }
  }
  &__boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__box {
    flex-basis: calc(50% - 12px);
    &--left {
      margin-right: 12px;
    }
    &--right {
      margin-left: 12px;
    }
    @media (max-width: 768px) {
      flex-basis: 100%;
      &--left {
        margin-right: 0;
      }
      &--right {
        margin-left: 0;
      }
    }
    padding: 24px;
    margin-bottom: 24px;
    background: #fff;
    box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
    border-radius: 12px;
    .vault-lable {
      font-family: 'Poppins';
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $colorTextGrey;
      padding-right: 10px;
    }
    .vault-value {
      font-family: 'Poppins';
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $colorNeutral900;
      text-align: right;
      &--small {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
        color: $colorTextGrey;
        padding-top: 4px;
      }
    }
    .vault-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .vault-form-button {
      min-width: 72.55px;
      padding: 8px;
      background: #dfe7fd;
      color: $colorPrimary;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem;
      border-radius: 8px;
      &:hover {
        cursor: pointer;
      }
    }
    // .vault-compounding {
    //   padding-bottom: 52px;
    // }
    .vault-apy {
      padding-bottom: 24px;
    }
    .vault-deposit {
      padding-bottom: 24px;
      .vault-value {
        font-family: 'Poppins';
      }
    }
    .vault-profit {
      padding: 12px 0;
      .vault-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 60%;
        &__coin {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 20px;
          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }
          .amount {
            padding-left: 8px;
            font-family: 'Poppins';
            font-weight: bold;
            font-size: 1.25rem;
            line-height: 1.5;
            color: $colorText;
          }
        }
      }
      .vault-form-button {
        border: none;
        height: 50%;
        &:disabled {
          pointer-events: none;
          background-color: $colorNeutral700;
          color: #e8e8e8;
        }
      }
    }
    .vault-contract {
      margin-bottom: 24px;
      .vault-value {
        display: flex;
        flex-direction: row;
        &__contract-address {
          color: $colorPrimary;
          font-family: 'Roboto';
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: bold;
        }
        &__img {
          cursor: pointer;
          width: 15px;
          height: 15px;
          fill: $colorTextGrey;
          margin-right: 4px;
        }
      }
    }
    .vault-divider {
      border-bottom: 1px solid $colorNeutral300;
      transform: rotate(-0.11deg);
    }
    .vault-reward {
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 1.25rem;
      color: $colorText;
      font-style: normal;
      line-height: 1.5;
      margin: 32px 0 15px 0;
    }
    .vault-your-balance {
      padding-bottom: 24px;
      .vault-value {
        font-family: 'Poppins';
        font-weight: bold;
      }
    }
    .vault-wallet-balance {
      margin-bottom: 24px;
      .vault-value {
        font-family: 'Poppins';
        font-weight: bold;
      }
    }
    .vault-tab {
      &__headers {
        display: flex;
        flex-direction: row;
        border-radius: 24px 24px 0 0;
        background: #fff;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.5rem;
        .tab-header {
          width: 100%;
          height: 100%;
          padding: 32px 0px 24px 0px;
          &:hover {
            cursor: pointer;
          }
          &__title {
            text-align: center;
          }
          &--active {
            border-bottom: 4px solid $colorPrimary;
            color: $colorPrimary;
          }
        }
      }
      &__body {
        margin: 48px 0 32px 0;
        text-align: center;
        // .vault-description {
        //   font-family: 'Poppins';
        //   font-size: 0.8rem;
        //   font-weight: 500;
        //   line-height: 1rem;
        // }
        .vault-form {
          margin-top: 8px;
          background-color: $colorNeutral200;
          height: 80px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 24px;
          &__input {
            border-radius: 24px;
            text-align: center;
            color: #737475;
            height: 70%;
            width: 85%;
            font-size: 1.5rem;
            background-color: $colorNeutral200;
            border: none;
            &::placeholder {
              text-align: center;
              color: $colorNeutral400;
            }
            &:focus {
              outline: none;
            }
            &[type='number'] {
              -moz-appearance: textfield;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
          }
          &__label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 10px;
            .vault-form-unit {
              color: $colorNeutral400;
              font-family: 'Poppins';
              font-size: 1rem;
              font-weight: 600;
            }
          }
          &--disabled {
            .vault-form__input {
              pointer-events: none;
            }
            .vault-form__label {
              .vault-form-button {
                pointer-events: none;
              }
            }
          }
        }
      }
      .vault-buttons {
        display: none;
        &--undefined {
          display: flex;
          .vault-button {
            pointer-events: none;
            background-color: inherit !important;
            color: $colorTextGrey !important;
          }
        }
        &--unapprove {
          display: flex;
          flex-direction: row;
          .vault-button {
            &__approve {
              background: $colorPrimary !important;
            }
            &__deposit {
              pointer-events: none;
              background-color: $colorTextGrey !important;
            }
          }
        }
        &--approve {
          & div {
            flex: 1 1;
          }
          display: flex;
          .vault-button {
            &__approve {
              pointer-events: none;
              background-color: $colorTextGrey !important;
            }
          }
        }
        &--grey {
          display: flex;
          .vault-button {
            pointer-events: none;
            background-color: $colorTextGrey !important;
          }
        }
        &--blue {
          & div {
            flex: 1 1;
          }
          display: flex;
        }
        &.vault-buttons--approve {
          display: flex;
        }
        .vault-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background: $colorPrimary;
          text-align: center;
          color: #fff;
          font-family: 'Poppins';
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 600;
          border-radius: 8px;
          height: 64px;
          border: none;
          &--left {
            margin-right: 14px;
          }
          &--right {
            margin-left: 14px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .vault-button:disabled {
          pointer-events: none;
          background-color: $colorTextGrey !important;
        }
      }
    }
  }
  @media (max-width: 1048px) {
    &__box {
      .vault-profit {
        .vault-value {
          &__coin {
            .amount {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .vault-header {
      &--large-screen {
        display: none !important;
      }
      &--small-screen {
        display: flex !important;
      }
    }
    &__coin-info {
      margin-top: 36px;
    }
  }
  @media (max-width: 400px) {
    &__coin-info {
      margin-top: 30px;
      margin-bottom: 30px;
      .coin-info-icon {
        width: 60px;
        height: 44px;
      }
      .coin-info-name {
        font-size: 1.5rem;
      }
    }
    &__box {
      &--right {
        height: 350px;
        margin-bottom: 0;
      }
      &--deposit {
        height: 400px;
        margin-bottom: 0;
      }

      .vault-lable {
        font-size: 1rem;
      }
      .vault-value {
        font-size: 1rem;
        &--small {
          font-size: 0.8rem;
        }
      }
      .vault-profit {
        .vault-value {
          flex-direction: column;
          align-items: flex-end;
          &__coin {
            padding-right: 0;
            padding-bottom: 10px;
            .amount {
              font-size: 1rem;
            }
          }
        }
        .vault-form-button {
          border: 1px solid #2963f9;
          font-size: 0.9rem;
          padding: 5px 10px;
          border-radius: 8px;
          background: $colorNeutral200;
        }
        .vault-form-button:disabled {
          border: none;
        }
      }
    }
    .vault-tab {
      &__headers {
        font-size: 1rem;
      }
      &__body {
        // .vault-description {
        //   font-size: 0.7rem;
        // }
        .vault-form {
          border: 1px solid $colorNeutral300;
          border-radius: 16px;
          height: 60px;
          &__input {
            width: 80%;
            text-align: left;
            padding-left: 10px;
            &::placeholder {
              text-align: left;
            }
            font-size: 1rem;
            &::placeholder {
              font-size: 1.2rem;
            }
          }
          &__label {
            width: 20%;
            justify-content: flex-end;
            .vault-form-unit {
              // font-size: 0.9rem;
              display: none;
            }
            .vault-form-button {
              border: 1px solid #2963f9;
              font-size: 0.9rem;
              padding: 5px 10px;
              border-radius: 8px;
              background: $colorNeutral200;
            }
          }
        }
      }
      .vault-buttons {
        flex-direction: column;
        .vault-button {
          margin-bottom: 10px;
          height: 56px;
          &--right {
            font-size: 0.9rem;
            margin-left: 3px;
          }
          &--left {
            font-size: 0.9rem;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .desc-withdraw {
    text-align: center;
    font-weight: bold;
  }
}
