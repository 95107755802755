.lib {
  //  Dropdown
  &-dropdown {
    position: relative;
    display: inline-block;

    &:hover {
      .lib-dropdown-content {
        display: block;
      }

      .lib-dropbtn {
        background-color: $colorNeutral300;
      }
    }
  }

  &-dropbtn {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $colorNeutral900;
    background: $colorNeutral200;
    padding: 8px 12px;

    border-radius: 4px;
    border: none;
  }

  &-droparrow {
    width: 0;
    height: 0;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $colorNeutral900;

    display: inline-block;
    margin-bottom: 2px;
    margin-left: 10px;
  }

  &-dropdown-buffer {
    position: absolute;
    width: 100%;
    height: 6px;
  }

  &-dropdown-content {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 6px);
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;
    border-radius: 5px;
    padding: 10px 0;

    &::before {
      content: '';
      position: absolute;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid white;
      top: -5px;
      left: 50%;
      transform: translate(-50%, 0px);
    }

    a {
      font-family: Poppins;
      color: $colorNeutral900;
      padding: 8px 12px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #e8e8f5;
      }
    }
  }

  // LinkNewTab
  &-link {
    font-family: Poppins;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $colorNeutral900;
    background: $colorNeutral200;
    padding: 8px 12px;

    border-radius: 4px;
    border: none;

    &:hover {
      background-color: $colorNeutral300;
      color: $colorNeutral900;
    }

    svg {
      margin-right: 4px;
    }

    &-img {
      margin-left: 10px;
    }
  }

  // Change
  &-change {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    .ic-arrow {
      margin: 0 8px 2px 0;

      &.white {
        path {
          fill: white;
        }
      }
    }

    &.up {
      color: $colorSuccess400;
    }

    &.down {
      color: $colorError400;
    }
  }

  // Tooltip
  &-tooltip {
    position: relative;

    &:hover {
      .lib-tooltip-content {
        display: block;
        visibility: visible;
        opacity: 1;
      }

      .lib-tooltip-buffer {
        display: block;
      }
    }

    &-icon {
      margin: 0 5px;
    }

    &-content {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 6px);

      min-width: 240px;
      padding: 12px;
      border-radius: 5px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
      z-index: 1;

      font-weight: 600;
      font-size: 12px;
      color: $colorNeutral700;
      background-color: white;
      white-space: pre-wrap;

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.15s linear;

      &::before {
        content: '';
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        top: -5px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    &-buffer {
      display: none;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 240px;
      height: 6px;
    }
  }

  // Tabs
  &-tabs {
    display: inline-flex;
    background: #efeff5;
    padding: 4px;
    border-radius: 8px;

    &-tab {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $colorNeutral500;

      padding: 8px 64px;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        color: $colorWhite;
        background: $colorPrimary;
      }
    }
  }
}

// Bootstrap Tooltip
#button-tooltip {
  opacity: 1 !important;
  .tooltip-inner {
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;

    font-weight: 600;
    font-size: 14px;
    color: $colorNeutral700;
    background-color: white;
    white-space: pre-wrap;
    text-align: start;
  }

  .arrow {
    &::before {
      border-bottom-color: white;
      border-top-color: white;
    }
  }
}

// Bootstrap Breadcrumb
.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  border: solid $colorNeutral700;
  border-width: 0 2px 2px 0;
  display: inline-block;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  height: 0px;
  padding: 3px;
  margin: 8px 12px 0 0;
}

.lib-search {
  background: $colorNeutral100;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  .search-icon {
    padding: 10px;
    margin: 0 auto;
  }
  input {
    background: $colorNeutral100;
    border: none;
    color: $colorAnalysisButtonText;
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
    height: 100%;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    color: $colorAnalysisButtonText;
    font-family: Poppins;
    font-size: 0.8rem;
  }
}
