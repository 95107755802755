.full-growth-height {
  display: flex;
  flex-direction: column;
}

.bridge-page {
  .bridge-main-box {
    .card {
      border: none;
      &-title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        color: $colorNeutral900;
        margin-bottom: 0;
        padding: 1rem;
        background-color: $colorNeutral200;
        border-radius: 24px 24px 0 0;
      }

      &-body {
        padding: 0;
      }

      .bridge {
        display: flex;
        align-items: center;
        background-color: $colorNeutral200;
        border-radius: 24px;
        padding: 1rem;
        margin: 1.25rem 0.5rem;

        .from,
        .to {
          text-align: center;
          flex: 1 1 50%;

          .name {
            font-size: 1.125rem;
            font-weight: 500;
            color: $colorNeutral900;
          }

          .network {
            color: $colorNeutral800;
          }
        }

        @media (max-width: 992px) {
          // margin: 1.25rem 0;

          .from,
          .to {
            .name {
              font-size: 1rem;
            }

            .network {
              font-size: 0.875rem;
            }
          }
        }
      }

      .rule {
        padding: 0.5rem;
      }
    }
  }
}

.bridge-history {
  margin-top: 40px;
}
