.nft {
  box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
    0px 10px 32px -4px rgba(15, 40, 61, 0.1);
  border-radius: 24px;
  margin-top: 1rem;
  padding: 1.5rem;
  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    span {
      margin-left: 1rem;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.25;
    }
  }
  &__filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .lib-tabs {
      min-width: 40vw;
      align-items: center;
      &-tab {
        flex: 1;
        border-radius: 8px;
        padding: 12px 0;
        text-align: center;
      }
    }
    .lib-tabs:first-child {
      margin-right: 1rem;
    }
    .lib-search {
      height: 100%;
    }
    .filter-icon {
      display: none;
      margin-left: 0.5rem;
    }
  }
  &__cards {
    .card-nft {
      font-family: 'Poppins', sans-serif;
      box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
        0px 10px 32px -4px rgba(15, 40, 61, 0.1);
      border-radius: 24px;
      padding: 24px;
      margin: 16px 0;
      &__header {
        position: relative;
        margin-bottom: 1.5rem;
        width: 100%;
        padding-top: 100%;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
          border-radius: 12px;
          // aspect-ratio: 1/1;
        }
      }
      &__type {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 12px;
        padding: 8px 12px;
        box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
          0px 10px 32px -4px rgba(15, 40, 61, 0.1);
      }
      &__name {
        color: $colorNeutral900;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      &__info {
        display: flex;
        justify-content: space-between;
        .nft-info-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: $colorNeutral700;
          font-size: 1rem;
          & div:first-child {
            margin-bottom: 0.5rem;
          }
          .status-available {
            color: #078d58;
          }
        }
      }
    }
  }

  &__empty {
    font-weight: 500;
    font-size: 1.5rem;
    color: $colorNeutral700;
    text-align: center;
    margin: 5rem auto;
  }

  @media (max-width: 1300px) {
    &__filter {
      .lib-tabs {
        min-width: 45vw;
      }
    }
  }

  @media (max-width: 1100px) {
    &__filter {
      .lib-tabs {
        min-width: 50vw;
      }
    }
  }

  @media (max-width: 1024px) {
    &__filter {
      justify-content: stretch;
      flex-direction: column-reverse;
      .group-filter {
        display: flex;
        flex-direction: row;
      }
      .lib-tabs {
        width: 100%;
        white-space: nowrap;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        &-tab {
          padding: 12px 32px;
          border: 1px solid $colorNeutral500;
          margin: 0 4px;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .lib-search {
        width: 100%;
        margin-bottom: 1rem;
        min-height: 56px;
      }
      .filter-icon {
        display: inline-block;
        background-color: #efeff5;
        padding: 16px 16px;
        border-radius: 8px;
      }
    }
  }
}

.nft-info {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: stretch;
  &__item {
    box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
    border-radius: 12px;
    padding: 24px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
  }
  &__desc {
    flex: 1 1;
    ul {
      margin-top: 1rem;
      color: $colorNeutral700;
      li {
        margin-bottom: 0.3rem;
      }
    }
  }
  &__lock_time {
    color: $colorNeutral900;
    font-weight: 600;
  }
  &__title {
    color: $colorNeutral900;
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 600;
  }

  // &__lock_time {
  //   flex: 1 1;
  // }
  // &__content div:last-child,
  // &__title {
  //   color: $colorNeutral900;
  //   font-size: 1.25rem;
  //   line-height: 1.5;
  //   font-weight: 600;
  // }
  // &__content {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-bottom: 1.5rem;
  //   color: $colorNeutral700;
  // }

  @media (max-width: 1200px) {
    &__desc {
      flex: 2 1;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    &__desc {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.nft-detail {
  padding: 1.5rem;
  box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
    0px 10px 32px -4px rgba(15, 40, 61, 0.1);
  border-radius: 24px 24px 24px 24px;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  &__header {
    height: 72px;
    display: flex;
    justify-content: space-between;
    & div:first-child {
      display: flex;
      align-items: baseline;
      .ic-left {
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
    h2 {
      margin: 0 0 0 1rem;
      font-size: 1.25rem;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      cursor: pointer;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
      &:hover {
        color: $colorPrimary400;
      }
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    .nft-main {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;
      flex: 1 1;
      &__img {
        position: relative;
        margin-bottom: 1.5rem;
        width: 100%;
        padding-top: 100%;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          object-fit: cover;
          border-radius: 12px;
        }
      }
      // &__price-wrap {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   margin-top: 1rem;
      // }
      // &__price {
      //   display: flex;
      //   flex-direction: column;
      //   & div:first-child {
      //     color: $colorNeutral700;
      //     font-size: 1rem;
      //   }
      //   & div:last-child {
      //     color: $colorSupply700;
      //     font-weight: 600;
      //     font-size: 2rem;
      //   }
      // }
      // &__btn button {
      //   padding: 12px 32px;
      //   font-size: 1rem;
      //   font-weight: 600;
      //   color: #fff;
      //   background-color: $colorPrimary400;
      //   border-radius: 8px;
      //   outline: none;
      //   border: none;
      //   &:hover {
      //     background-color: $colorPrimary900;
      //   }
      // }
    }
    .nft_info {
      flex: 1.5 1;
      &__general {
        h1 {
          font-weight: 600;
          font-size: 2rem;
          color: $colorNeutral900;
          line-height: 40px;
          margin-bottom: 16px;
        }
      }
      &__type {
        margin-bottom: 24px;
        span {
          padding: 8px 12px;
          border-radius: 12px;
          background-color: #078d58;
        }
      }
      &__des {
        h2 {
          font-size: 1.25rem;
          font-weight: 600;
          color: $colorNeutral900;
          margin-bottom: 8px;
        }
        div {
          color: $colorNeutral700;
          font-size: 1rem;
          font-weight: normal;
          line-height: 32px;
        }
      }
      .line {
        height: 1px;
        width: 100%;
        background-color: #d6d9db;
        margin: 1rem 0;
      }
      &__main {
        .nft-main {
          &__item {
            display: flex;
            justify-content: space-between;
            padding: 0.5rem 0;
            & div:first-child {
              color: $colorNeutral700;
              font-size: 1rem;
              font-weight: normal;
              line-height: 24px;
            }
            & div:last-child {
              color: $colorNeutral900;
              font-size: 1rem;
              line-height: 20px;
              font-weight: bold;
            }
            .contract {
              display: flex;
              div {
                color: $colorPrimary400 !important;
              }
              &__img {
                cursor: pointer;
                width: 15px;
                height: 15px;
                fill: #717981;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1700px) {
    width: 90%;
  }
  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    &__body {
      flex-direction: column;
      .nft-main {
        margin-bottom: 2rem;
        margin-right: 0;
      }
    }
  }
}
