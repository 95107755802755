.connect-wallet-copy {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0.665rem;
  padding-right: 0.665rem;
  padding-bottom: 1.33rem;
  width: 100%;
}

.modal-header {
  border-bottom: 0 none;
  // padding: 0 !important;
}

.modal-content {
  border-radius: 20px;
}

.dialog {
  .bg-dialog-choose-wallet {
    position: relative;
    background: $colorWhite;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
    .pickgradient {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      .bg-gadient {
        position: relative;
        height: 250px;
        background: radial-gradient(
            61.22% 95.06% at -9.72% -17.89%,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(42, 130, 130, 0.2) 51.72%,
            rgba(95, 130, 255, 0.1) 79.1%,
            rgba(238, 32, 32, 0) 100%
          ),
          radial-gradient(
            122.18% 103.83% at 71.91% -16.17%,
            rgba(240, 101, 67, 0.8) 0%,
            rgba(64, 11, 100, 0.3) 65.79%,
            rgba(20, 0, 142, 0) 100%
          ),
          #000120;
        border-radius: 0 0 50% 50%/0 0 75% 75%;
        transform: scaleX(1.5);
        overflow: hidden;
      }
      .bg-img-modal {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%) scaleX(0.664);
      }
    }

    .close-x button {
      background-image: url('../home/ic_close_white.svg') !important;
    }
  }

  .bg-dialog-connect-wallet {
    position: relative;
    background: $colorWhite;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
    .dialog-header {
      .close-x button {
        background-image: url('../home/ic_close_white.svg') !important;
      }
    }
    .connect-choices {
      margin-top: 280px;
      .connecting-ring {
        background: $colorWhite;
        border-color: $colorWhite;
        min-height: 135px;
        min-width: 135px;
        .rotate-circle {
          width: 100%;
          height: 100%;
          border-width: 8px;
          border-color: $colorPrimary #f8f8fb #f8f8fb #f8f8fb;
        }
      }
      .wrong-network {
        box-shadow: 0px 8px 12px rgba(18, 49, 150, 0.27);
        border: 1px solid $colorError400;
        background: $colorBgError;
        position: relative;
        cursor: pointer;
        border-radius: 61px;
        padding: 8px;
        margin-top: 0.5rem;
        .btn-content {
          .text-connect {
            color: $colorError400;
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    .text-error {
      color: $colorError400;
      .link-download {
        color: $colorPrimary400;
        cursor: pointer;
      }
    }
    .header-connect-wallet {
      .pickgradient {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        .bg-gadient {
          position: relative;
          height: 250px;
          background: radial-gradient(
              61.22% 95.06% at -9.72% -17.89%,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(42, 130, 130, 0.2) 51.72%,
              rgba(95, 130, 255, 0.1) 79.1%,
              rgba(238, 32, 32, 0) 100%
            ),
            radial-gradient(
              122.18% 103.83% at 71.91% -16.17%,
              rgba(240, 101, 67, 0.8) 0%,
              rgba(64, 11, 100, 0.3) 65.79%,
              rgba(20, 0, 142, 0) 100%
            ),
            #000120;
          overflow: hidden;
        }
        .img-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  // .pickgradient {
  //   width: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;

  //   .bg-dialog-choose-wallet {
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     width: 100%;
  //   }

  //   &:before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background: linear-gradient(134.72deg, #6fff00 0%, #005bff 65.74%);
  //     border-radius: 0 0 50% 50%/0 0 100% 100%;
  //     z-index: 1;
  //   }
  // }

  .dialog-header {
    display: flex;
    flex-flow: inherit;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    position: relative;

    .back {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;

      button {
        background-color: transparent;
        border: 0;
        padding: 0.5rem 1rem;
        margin: 0.5rem 0;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: url('../home/ic_back.svg') 0 0 repeat;
          background-size: 100% 100%;
        }
      }
    }

    .close-x {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;

      button {
        background-color: transparent;
        background-image: url('../home/ic_close.svg');
        background-size: 100% 100%;
        border: 0;
        padding: 0.5rem 1rem;
      }
    }
  }
}

.tems-agreement {
  margin-top: 120px;

  .small {
    color: $textColor;
    font-size: 14px;
    text-align: center;

    a {
      border-bottom: 1px solid transparent;
      color: $textColor;
      font-weight: 800;
      font-size: 0.75rem;
      letter-spacing: 0;
      margin: 0px;
      text-decoration: none;
      text-transform: none;
    }
  }
}

@mixin rifi-item {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

@mixin rifi-item-icon {
  flex-shrink: 0;
  display: inline-block;
  background-repeat: no-repeat;
  margin: 0;
  background-position: center;
  background-size: contain;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
}

@mixin rifi-item-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 30px;
  margin-left: 20px;
  margin-bottom: 0;
  text-decoration: none;
}

.connect-choices {
  margin-top: 280px;
  width: 100%;

  .connecting-ring {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .title-wallet {
    h4 {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: $colorBlack;
    }

    p {
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $colorNeutral700;
    }
  }

  .connect-item {
    @include rifi-item;
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    }

    .arrow {
      height: 25px;
      width: 25px;
      margin: 0;
    }

    .connect-wallet-icon {
      @include rifi-item-icon;
    }

    .wrapper {
      flex: 1;
    }

    .connect-item-text {
      color: $textColor;
      @include rifi-item-text;
    }

    .connect-item-text-coming-soon {
      color: $textComingSoon;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      margin-left: 20px;
      margin-bottom: 0;
      text-decoration: none;
    }
  }
  .line {
    height: 2px;
    margin-left: 38px;
    margin-right: 38px;
    background: #9cf8e1;
    opacity: 0.3;
  }

  @media (max-width: 576px) {
    .connect-item {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.arrow.big.green {
  background-image: url('../home/ic_arrow.svg');
  background-repeat: no-repeat;
}

.arrow.big {
  background-position: center;
  background-size: 100%;
  transition: all 0.2s ease-in-out;
}

.title-header {
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: 48px;
}

.ether-header {
  position: relative;
  font-size: 1.1rem;
  width: 100%;
  border-radius: 20px;
  padding: 0px;

  // @media (min-width: 576px) {
  //   padding: 16px;
  // }

  .title-header {
    font-size: 20px;
    margin-left: 16px;
  }

  .close-x {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    z-index: 2;

    button {
      width: 36px;
      height: 36px;
      background-color: transparent;
      background-image: url('../home/ic_close_v2.svg');
      border: 0;
      background-repeat: no-repeat;
      padding: 0.5rem 1rem;
    }
  }
}

@mixin ether-tab__item {
  flex: 1;
  padding-bottom: 16px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
}

.ether-body {
  padding: 0;

  .ether-content {
    margin: 24px 28px;

    @media (max-width: 576px) {
      margin: 22px 16px;
    }

    span {
      width: 14px;
      height: 14px;
      margin-left: 10px;
      background-image: url('../home/ic_link.svg');
      background-repeat: no-repeat;
      margin-bottom: 14px;
    }

    .line {
      height: 1px;
      background: $colorNeutral300;
      margin-bottom: 24px;
    }

    &__description {
      color: $colorNeutral600;
      margin-bottom: 36px;
    }

    &__limit {
      margin: 0 -28px -24px;
      padding: 22px 28px 24px;
      background: #f0f0f8;
      border-radius: 0 0 20px 20px;
      @media (max-width: 576px) {
        margin: 0 -16px -22px;
        padding: 22px 16px;
      }
      .borrow-limit-progress-bar {
        div {
          max-width: 100% !important;
        }
      }
    }

    .ether-link {
      color: $textColor;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      margin-bottom: 14px;
      margin-top: 18px;
    }

    .bg-borrow-limit-user {
      margin-bottom: 24px;

      .borrow-limit-progress-bar {
        height: 10px !important;
        div {
          max-width: 100% !important;
        }
      }
    }

    .ether-supply-item {
      margin-bottom: 8px;

      @include rifi-item;

      .ether-supply-item-icon {
        border-radius: 50%;
        @include rifi-item-icon;
      }

      .wrapper {
        flex: 1;
        margin-right: 8px;
      }

      .ether-supply-percent {
        font-family: 'Roboto', sans-serif;
        color: $colorNeutral900;
        @include rifi-item-text;
        display: flex;
        align-items: center;
        margin-left: 0 !important;
        max-width: 250px;
        overflow: hidden;

        span {
          width: 16px;
          height: 16px;
          margin: 0 10px;
          background-image: url('../home/ic_arrow_v2.svg');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .button {
      padding: 18px 24px;
      font-size: 20px;
      line-height: 24px;
      text-transform: unset;
    }
  }

  .ether-supply-text {
    color: $colorNeutral800;
    @include rifi-item-text;
    margin-left: 0 !important;
  }

  .text-noti {
    color: $colorNeutral600;
    text-align: center;
    padding: 0 48px;
    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .ether-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 24px;

    .ether-form {
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 0 32px;
      @media (max-width: 576px) {
        margin: 0 16px;
      }

      .max {
        position: absolute;
        right: 20px;
        // bottom: 25px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        background-color: white;
        color: $colorPrimary;
        text-transform: uppercase;
        padding: 8px 16px;
        border-radius: 7px;
        border: 1px solid #0029ff;
        cursor: pointer;
      }

      .text-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        margin-right: 25px;
        position: absolute;
        color: $colorHeaderApp !important;
      }

      .wallet-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        color: $colorNeutral900;
        font-weight: 600;
        @media (max-width: 375px) {
          font-size: 14px;
        }
      }
      .input-box {
        background-color: $colorNeutral200;
        border: 1px solid $colorNeutral300;
        border-radius: 16px;
      }
      input {
        min-height: 50px;
        font-weight: 600;
        font-size: 40px;
        font-style: normal;
        border-width: 0;
        border-radius: 16px;
        line-height: 55px;
        outline: 0;
        width: calc(100% - 100px);
        padding: 1rem;
        background-color: $colorNeutral200;
        transition: all 0.2s linear;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          -moz-appearance: textfield;
        }
        &.text-30 {
          font-size: 30px;
        }
        &.text-36 {
          font-size: 36px;
        }
      }
    }
    .ether-tab-box {
      margin-bottom: 20px;
      padding: 0 72px;
      border-bottom: 1px solid #34404b25;
      @media (max-width: 576px) {
        padding: 0;
      }
      .ether-tab {
        display: flex;
        &-supply {
          @include ether-tab__item;
          color: $colorNeutral900;
          border-bottom: 4px solid $colorNeutral900;
        }

        &-borrow {
          @include ether-tab__item;
          color: $colorNeutral900;
          border-bottom: 4px solid $colorNeutral900;
        }

        &__inactive {
          color: $colorNeutral500;
          border-bottom: 4px solid transparent;
        }
      }
    }
  }
}

.modal-dialog {
  .icon-token {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .text-enable {
    margin: 0 50px;
    text-align: center;
    color: $colorHeaderApp;
  }
}
.modal-confirm-transaction {
  .modal-content {
    border-radius: 16px;
    .ether-header {
      border-radius: 0px;
      background-color: transparent;
    }
  }
  .modal-dialog {
    .modal-body {
      padding: 24px 24px;
    }
    .confirm-transaction {
      .close-x {
        position: absolute;
        right: 24px;
        top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 36px;
          height: 36px;
          background-color: transparent;
          background-image: url('../home/ic_close_v2.svg');
          border: 0;
          background-repeat: no-repeat;
        }
      }
      .title-header {
        padding-top: 50px;
        padding-bottom: 12px;
        text-align: center;
        color: $colorNeutral900;
        font-weight: 600;
        font-size: 1.5rem;
        @media (max-width: 576px) {
          font-size: 1.3rem;
        }
      }
      .description {
        color: $colorNeutral700;
        font-size: 14px;
        font-weight: 500;
      }
      .connecting-ring {
        margin-top: 12px;
        margin-bottom: 66px;
        background: $colorWhite;
        border-color: $colorWhite;
        min-height: 135px;
        min-width: 135px;
        .rotate-circle {
          width: 100%;
          height: 100%;
          border-width: 8px;
          border-color: $colorPrimary #f8f8fb #f8f8fb #f8f8fb;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    @media (min-width: 576px) {
      max-width: 414px;
    }
  }
}
.modal-dialog.modal-dialog-centered {
  @media (max-width: 576px) {
    margin: 0 auto;
  }
}
