$colorFooter: #04d7a2;
$colorRedApp: #f93232;
$colorGreenApp: #00b997;
$colorHeaderApp: #a2a1a4;
$colorFormEther: #fafafa;
$colorWhite: #fff;
$colorBody: #121c24;
$textColor: #060508;
$colorVioletApp: #7517f8;
$textComingSoon: #986800;
$colorGrey: #3b3b43;
$colorRoadMap: #eaeaea;
$colorBlueDark: #162a72;
$colorBlue: #1752af;
$colorBlueLight: #08a1ff;
$colorBlueNomal: #1457ef;
$colorGreen: #00bcbc;
$colorGreenLight: #22f4f4;
$colorYellow: #ffcb5b;
$colorOrange: #feb705;
$colorOrangeDark: #ff8206;
$colorRedDrak: #bf0808;
$colorBlueName: #1968fc;
$colorRedRoadmap: #ff0000;
$colorOrangeRoadmap: #ffab40;
$colorBlueRoadMap: #1968fc;
$colorNegative: #f3574f;
$colorPositive: #3fcc28;
$colorSideBar: #f3f4f8;
$colorText: #34404b;
$colorTextGreen: #13d198;
$colorTextBlue: #2c37a2;
$colorTextDarkBlue: #262f7b;
$colorDashboard: #313ca4;
$colorRedDown: #e25959;
$colorBlack: #000000;
$colorTextLightBlue: #42e8e0;
$colorGreenPercent: #32e75a;
$colorTextBlack: #313131;
$colorThTable: #5d666f;
$colorNeutral500: #9aa0a5;
$colorTrTable: #f9f9fb;
$colorWhiteDark: #fafff7;
$colorNeutral100: #efeff5;
$colorNeutral200: #f8f9f9;
$colorNeutral300: #d6d9db;
$colorNeutral400: #c2c6c9;
$colorNeutral600: #858c93;
$colorNeutral700: #717981;
$colorNeutral800: #5d666f;
$colorNeutral900: #34404b;
$colorError400: #ef5350;
$colorBgError: rgba(255, 96, 96, 0.2);
$colorSuccess400: #00c267;
$colorPrimary: #2963f9;
$colorBorrow900: #2441a3;
$colorBorrow700: #2971d6;
$colorBorrow500: #2b91f9;
$colorBorrow300: #63b2fc;
$colorBorrow100: #bbddfe;
$colorBorrow50: #e3f2fe;
$colorSecondaryBorrow: #52e07a;
$colorYellowProgess: #fff468;
$colorToggle: #3540a6;
$colorBoxMarket: #2832a0;
$colorProgess: #5760c6;
$colorProgessSupply: #16e14f;
$colorProgessBorrow: #63b2fc;
$colorTextGrey: #717981;
$colorAnalysisButtonText: #8f8f94;
$colorAnalysisTableRow: #f9f9fb;
$colorThumbScrollbar: #0969ea;
$colorPrimary400: #2963f9;
$colorPrimary900: #0000dc;
$colorBgMax: #c6caf9;
$colorBgSupply: #16e14f;
$colorBgBorrow: #2b83ea;
$colorTabDialog: #3f6bdd;
$colorBlueMagenta: #130f26;
$colorSupply700: #078d58;
$colorSupply500: #19ae70;
$colorSupply50: #e5f5ed;
$colorSideBarActive: #2b349b;
$colorThLiquidate: #34404b;
$colorButtonLiquidate: #1bf3b5;
$colorLogo: #fcb100;

%scrollbar {
  width: 0.3rem;
  height: 0.5rem;
  background-color: $colorWhite;
  border-radius: 0.6rem;
}

%scrollbar-thumb {
  background: $colorThumbScrollbar;
  border-radius: 0.6rem;
}
