.market-page {
  @media (max-width: 1024px) {
    .title-page {
      display: none !important;
    }
    .market-analysis__page-title {
      display: flex;
      align-items: flex-end;
    }
  }

  .market-analysis {
    &__headers {
      .header_name {
        display: inline-block;
      }
    }
    &__description {
      max-width: 600px;
      margin: 30px 0;
    }
    &__page-title {
      display: none;
      margin: 24px 0;
      .icon-title {
        margin-right: 12px;
      }
      h2 {
        display: inline-block;
        font-family: 'Poppins';
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.5rem;
      }
      @media (max-width: 1024px) {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .market-box-wrapper {
    .market-box {
      &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        flex: 1 1 0px;
      }
      &__header-item {
        margin-bottom: 10px;
      }
      &__tabs {
        display: inline-flex;
        justify-content: center;
        width: 334px;
        height: 48px;
        background-color: $colorNeutral100;
        border-bottom: none;
        font-size: 1rem;
        font-family: 'Poppins';
        font-weight: 600;
        line-height: 2.5rem;
        border-radius: 8px;
        text-align: center;
        @media (max-width: 675px) {
          width: 58%;
        }
        @media (max-width: 530px) {
          width: 100%;
        }
        @media (max-width: 400px) {
          width: 100%;
        }
      }
      &__tab {
        cursor: pointer;
        margin: 4px auto 4px auto;
        width: 49%;
        height: 40px;
        color: #9aa0a5;
        border-radius: 4px;
        &.active {
          background-color: $colorPrimary;
          color: #fff;
        }
      }
      &__search {
        width: 277px;
        height: 48px;
        background: $colorNeutral100;
        border-radius: 8px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        .search-icon {
          // margin: 0 8px;
          // width: 24px;
          // height: 24px;
        }
        input {
          background: $colorNeutral100;
          border: none;
          color: $colorAnalysisButtonText;
          border-radius: 8px;
          font-size: 1rem;
          width: 100%;
          height: 100%;
        }
        input:focus {
          outline: none;
        }
        input::placeholder {
          color: $colorAnalysisButtonText;
          font-family: Poppins;
          font-size: 0.8rem;
        }
        @media (max-width: 675px) {
          width: 40%;
        }
        @media (max-width: 530px) {
          width: 100%;
        }
        @media (max-width: 400px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 400px) {
        &__header {
          display: block;
        }
        &__search {
          width: 100%;
          margin-bottom: 1rem;
        }
      }
      .infinite-scroll-component {
        overflow: unset !important;

        &::-webkit-scrollbar {
          @extend %scrollbar;
        }
        &::-webkit-scrollbar-thumb {
          @extend %scrollbar-thumb;
        }
        .table-responsive {
          overflow-x: unset;
        }
      }
    }
    // CSS FOR TABLE
    .table-wrap {
      min-height: calc(100vh - 436px);
      .table-rating {
        overflow: unset;
        // font-family: Mulish;
        // font-style: normal;
        // font-weight: bold;
        margin-bottom: 50px;
        margin-top: 38px;
        border: none;
        // @media (min-width: 1920px) {
        //   width: 75%;
        // }
        // @media (min-width: 2560px) {
        //   width: 60%;
        // }
        &__th {
          color: $colorPrimary;
          font-size: 0.9rem;
          line-height: 1rem;
          font-weight: 500;
          font-family: 'Poppins';
          &:first-child {
            // text-align: left;
            padding-left: 24px;
          }
        }
        &__td {
          font-family: Roboto;
          text-align: right;
          font-size: 1rem;
          line-height: 1.1rem;
          cursor: pointer;
          color: $colorText;
          font-weight: normal;
          align-items: center;
          &:first-child {
            text-align: left;
            color: #04d7a2;
            padding-left: 24px;
          }
          .coin-info {
            width: 100%;
            display: flex;
            align-items: center;
            &__avatar {
              width: 2.5rem;
              height: 2.5rem;
              min-width: 2.5rem;
              min-height: 2.5rem;
              margin-right: 0.3rem;
            }
            &__label {
              &--large {
                color: $colorText;
                font-family: 'Poppins';
                font-weight: normal;
                font-size: 1rem;
                line-height: 1.5rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.name-coin {
                  width: 150px;
                }
              }
              &--small {
                font-family: Roboto;
                font-weight: normal;
                font-size: 0.8rem;
                line-height: 1rem;
                color: #717981;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }

        .rt-tr {
          &:hover {
            .table-rating__td {
              background: #eff2f5 !important;
              &:first-child {
                border-left: 0.3rem solid $colorPrimary;
              }
            }
          }
          align-items: center;
          &.-odd {
            .rt-td {
              background-color: $colorAnalysisTableRow;
            }
          }
        }
        .rt-thead {
          .rt-tr {
            overflow: hidden;

            &:hover {
              background: none;
            }
          }
        }
        .rt-table {
          overflow: unset;
          &::-webkit-scrollbar {
            @extend %scrollbar;
          }
          &::-webkit-scrollbar-thumb {
            @extend %scrollbar-thumb;
          }
          .table-responsive {
            overflow-x: unset;
          }
          .rt-thead {
            box-shadow: none;
            min-width: unset !important;
            // max-width: 1060px;
            .rt-th {
              pointer-events: none;
              border-right: none;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 32px 5px;
              font-size: 0.875rem;
              line-height: 1rem;
              &.-sort-asc {
                box-shadow: none;
                &::before {
                  content: url('../icons/ic_sort_down.svg');
                  transform: rotate(180deg);
                  color: #717981;
                  width: 20px;
                  height: 20px;
                }
              }
              &.-sort-desc {
                box-shadow: none;
                &::before {
                  content: url('../icons/ic_sort_down.svg');
                  vertical-align: middle;
                  color: #717981;
                }
              }
              &:first-child {
                justify-content: flex-start;
                padding-left: 24px;
                @media (max-width: 1700px) {
                  width: 220px !important;
                }
                @media (max-width: 550px) {
                  &.collapse {
                    width: 50px !important;
                  }
                  &.expand {
                    width: 100px !important;
                  }
                  .icon-collapse-expand {
                    display: inline-block;
                  }
                }
                @media (max-width: 1417px) {
                  box-shadow: 0px 0px 20px rgba(42, 41, 67, 0.12);
                }
              }
              &:last-child {
                padding-right: 24px;
              }
              .market-analysis__headers {
                pointer-events: visible;
              }
              .icon-collapse-expand {
                display: none;
              }
            }
          }
          .rt-tbody {
            overflow: auto;
            min-width: unset !important;
            // max-width: 1060px;
            .rt-tr-group {
              border-bottom: none;
              @media (max-width: 1024px) {
                width: max-content;
              }
            }
            .rt-td {
              border-right: none;
              display: flex;
              justify-content: flex-end;
              &:last-child {
                padding-right: 24px;
              }
              &:first-child {
                @media (max-width: 1700px) {
                  width: 220px !important;
                }
                @media (max-width: 550px) {
                  &.collapse {
                    width: 50px !important;
                    .coin-info {
                      padding-left: 8px;
                      &__label {
                        display: none;
                      }
                    }
                  }
                  &.expand {
                    width: 100px !important;
                  }
                  padding-left: 0;
                  .coin-info {
                    justify-content: center;
                    &__avatar {
                      width: 2rem;
                      height: 2rem;
                      min-width: 2rem;
                      min-height: 2rem;
                      margin-right: 0.3rem;
                    }
                    &__label {
                      &--large {
                        display: none;
                      }
                      &--small {
                        font-weight: bold;
                        font-size: 0.8rem;
                        color: black;
                      }
                    }
                  }
                }
                @media (max-width: 1417px) {
                  box-shadow: 0px 30px 20px rgba(42, 41, 67, 0.12);
                }
              }
            }
          }
        }
      }
    }
  }
}
