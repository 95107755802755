.pagination {
  justify-content: flex-end;
  margin-top: 40px;

  .prev-page,
  .next-page {
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disable {
      background: $colorNeutral400;
      .icon-page {
        path {
          stroke: $colorBlueMagenta;
        }
      }
    }
    &.active {
      background: $colorPrimary400;
      .icon-page {
        path {
          stroke: $colorWhite;
        }
      }
    }
  }

  .page-item {
    padding: 4px;
    .btn {
      font-family: 'Roboto';
      font-size: 1rem;
      line-height: 1.25rem;
      color: $colorNeutral400;
      background-color: transparent;
      border-color: transparent;
      // margin-right: 10px;
      text-align: center;
      padding: 0.25rem 0.7rem;
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
      &:active {
        background-color: transparent;
        border-color: transparent;
      }
    }
    &.active {
      .btn {
        color: $colorPrimary400;
        // background-color: $colorTextDarkBlue;
      }
    }
  }
}
