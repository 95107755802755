.liquidate {
  margin-top: 2rem;
  .title-page {
    &.media {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 3rem;
      color: $colorTextBlack;
      display: flex;
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
  .liquidate-col {
    padding: 15px;
    &.col-form {
      max-height: calc(100vh - 103px);
      overflow: auto;
    }
  }
  .box-table-liquidate {
    box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
      0px 10px 32px -4px rgba(15, 40, 61, 0.1);
    border-radius: 1.25rem 1.25rem 0rem 0rem;
    position: relative;
    .filter-box {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      background: $colorWhite;
      box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
        0px 10px 32px -4px rgba(15, 40, 61, 0.1);
      border-radius: 24px;
      padding: 20px;
      min-width: 60%;

      .title-form {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $colorNeutral300;
        padding: 9px 5px 30px 10px;
        font-weight: bold;
        color: $colorThLiquidate;
        margin-bottom: 20px;
        .icon-filter {
          cursor: pointer;
          g {
            path {
              fill: $colorNeutral500;
            }
          }
        }
        .icon-filter {
          &.active {
            g {
              path {
                fill: $colorPrimary;
              }
            }
          }
        }
      }
      .filter-content {
        .form-select {
          &__control {
            margin: 10px 0px;
            border-radius: 8px;
            background: $colorNeutral100;
            outline: none;
            border: none;
            padding: 10px 5px;
            margin-left: 40px;
          }
        }
        .form-input {
          input {
            border: none;
            outline: none;
            border-radius: 8px;
            background: $colorNeutral100;
            padding: 15px 20px;
            width: calc(100% - 40px);
            margin-left: 40px;
          }
        }
        .btn-box {
          border-top: 1px solid $colorNeutral300;
          padding-top: 20px;
          display: grid;
          grid-template-columns: auto;
          grid-gap: 10px;
          .btn {
            border: none;
            box-shadow: none;
            font-weight: bold;
            padding: 10px 12px;
          }
          .btn-cancel {
            background: $colorNeutral300;
            color: $colorNeutral500;
            &:hover {
              background: darken($color: $colorNeutral300, $amount: 10);
            }
          }
          .btn-submit {
            color: $colorWhite;
            background: $colorPrimary;
            &:hover {
              background: darken($color: $colorPrimary, $amount: 10);
            }
          }
        }
      }
    }
    .ReactTable {
      border: none;
      .rt-thead {
        .rt-th {
          display: flex;
          align-items: center;
          padding-top: 25px;
          padding-bottom: 15px;
          color: $colorThLiquidate;
          font-weight: bold;
          white-space: break-spaces;
          text-align: start;
          border: none;
          border-bottom: 1px solid $colorNeutral300;
          &:first-child {
            padding-left: 35px;
          }

          .icon-filter {
            cursor: pointer;
            g {
              path {
                fill: $colorNeutral500;
              }
            }
          }
          .icon-filter {
            &.active {
              g {
                path {
                  fill: $colorPrimary;
                }
              }
            }
          }
        }
      }
      .rt-tbody {
        max-height: calc(100vh - 223px);
        .rt-tr {
          cursor: pointer;
          .rt-td {
            color: $colorThLiquidate;
            padding: 15px 0px;
            &:first-child {
              padding-left: 35px;
            }
            &.rate {
              color: $colorPrimary400;
              font-weight: 500;
              font-family: 'Roboto';
            }

            .icon-copy {
              cursor: pointer;
            }
          }
        }
      }
      .rt-noData {
        top: 150%;
      }
    }
  }
  .liquidate-box {
    background: $colorWhite;
    box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
      0px 10px 32px -4px rgba(15, 40, 61, 0.1);
    border-radius: 24px;
    padding: 20px;
    .title {
      font-weight: bold;
      color: $colorThLiquidate;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .select-token {
      &__control {
        margin: 20px 0px;
        width: 100%;
        border-radius: 8px;
        background: $colorNeutral100;
        outline: none;
        border: none;
        padding: 10px 5px;
      }
      &__indicator-separator {
        display: none;
      }
      &__single-value {
        color: $colorThLiquidate;
        font-weight: bold;
      }
      &__menu-list {
        max-height: 250px;
        overflow: auto;
        &::-webkit-scrollbar {
          @extend %scrollbar;
        }
        &::-webkit-scrollbar-thumb {
          @extend %scrollbar-thumb;
        }
      }
    }

    .list-item {
      .box-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        color: $colorThLiquidate;
        .value {
          font-weight: bold;
        }
      }
    }
    .input-amount {
      width: 100%;
      border: none;
      outline: none;
      border-radius: 8px;
      background: $colorNeutral100;
      padding: 15px 20px;
    }
    .btn-liquidate {
      width: 100%;
      background: $colorPrimary;
      color: $colorWhite;
      font-weight: bold;
      border-radius: 40px;
      border: none;
      outline: none;
      box-shadow: none;
      margin: 0px 5px;
      &:hover {
        background: darken($color: $colorPrimary, $amount: 10);
      }
      &:disabled {
        cursor: no-drop;
      }
    }
  }
}
