.page-not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title-not-found {
    color: $colorBlack;
    text-transform: uppercase;
  }
  .btn-home-page {
    display: flex;
    justify-content: center;
  }
}
