.chart {
  display: flex;

  .dot {
    height: 10px;
    width: 13px;
    margin-top: 7px;
    margin-right: 10px;
    border-radius: 5px;
  }

  .tokenName {
    font-size: 14px;
    color: $colorWhite;
  }

  .percentage {
    font-size: 14px;
    font-weight: bold;
    color: $colorWhite;
  }
}
