.vault {
  font-family: Poppins;

  &-spinner {
    text-align: center;
    padding-top: 100px;
  }

  &-header {
    &.header-1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      color: $colorNeutral900;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &.header-2 {
      display: none;

      @media (max-width: 768px) {
        display: block;
        font-weight: 600;
        font-size: 30px;
        line-height: 24px;
        margin-top: 24px;
      }

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    &-icon {
      margin-right: 12px;
    }
  }

  &-stats {
    display: flex;
    flex-wrap: wrap;
    padding-top: 32px;

    &-primary {
      flex-basis: 50%;

      @media (max-width: 1376px) {
        flex-basis: 100%;
        text-align: center;
      }

      @media (max-width: 480px) {
        text-align: left;
      }

      .stat {
        &-name {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $colorNeutral900;

          @media (max-width: 1376px) {
            font-size: 14px;
          }

          @media (max-width: 480px) {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
        }

        &-number {
          font-family: Roboto;
          font-weight: bold;
          font-size: 60px;
          line-height: 48px;
          color: $colorPrimary;
          margin-top: 16px;

          @media (max-width: 1376px) {
            font-size: 52px;
          }

          @media (max-width: 568px) {
            font-size: 42px;
          }

          @media (max-width: 480px) {
            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
            margin-top: 8px;
          }
        }
      }
    }

    &-secondary {
      flex-basis: 50%;

      display: flex;
      justify-content: space-around;

      @media (max-width: 1376px) {
        flex-basis: 100%;

        margin-top: 32px;
      }

      @media (max-width: 480px) {
        display: block;
        text-align: left;

        margin-top: 20px;
      }

      .stat {
        &-name {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $colorNeutral900;

          @media (max-width: 1376px) {
            font-size: 14px;
          }
        }

        &-number {
          font-family: Roboto;
          font-weight: bold;
          font-size: 36px;
          line-height: 48px;
          color: $colorPrimary;
          margin-top: 4px;

          @media (max-width: 1376px) {
            font-size: 32px;
          }

          @media (max-width: 568px) {
            font-size: 30px;
          }

          @media (max-width: 480px) {
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            color: $colorNeutral900;
            margin-top: 8px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  &-pools {
    margin-top: 48px;

    @media (max-width: 480px) {
      margin-top: 12px;
    }
  }

  &-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    .lib-tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 300px;
      &__active {
        .active {
          background-color: #49ba85;
        }
      }
      &-tab {
        border-radius: 8px;
        padding: 12px 0;
        flex: 1 1;
        text-align: center;
      }
    }

    @media (max-width: 768px) {
      .lib-tabs {
        min-width: 270px;
      }
    }

    @media (max-width: 600px) {
      flex-direction: column;
      .lib-tabs {
        margin-bottom: 1rem;
      }
    }
  }

  &-empty {
    font-weight: 500;
    font-size: 1.5rem;
    color: $colorNeutral700;
    text-align: center;
    margin: 5rem 0;
  }

  &-pool {
    background: white;
    border: 2px solid transparent;
    box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
    border-radius: 24px;
    padding: 24px;
    margin-bottom: 28px;

    display: flex;
    position: relative;
    cursor: pointer;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 480px) {
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 28px;
      }
    }

    &:hover {
      border-color: $colorPrimary;

      &::before {
        content: '';
        width: 6px;
        height: 114px;
        background-color: $colorPrimary;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        @media (max-width: 480px) {
          height: 6px;
          width: 114px;
          top: calc(100% - 6px);
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }

    & > div {
      flex-basis: 50%;

      &:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 64px 0 8px;

        @media (max-width: 1376px) {
          padding: 0 0 0 4px;
        }

        @media (max-width: 480px) {
          display: block;
          text-align: center;
        }
      }

      &:last-of-type {
        padding: 0 40px 0 20px;

        @media (max-width: 1376px) {
          padding: 0 8px 0 24px;
        }

        @media (max-width: 768px) {
          padding: 0 4px 0 4px;
        }
      }
    }

    &-icon {
      width: 56px;
      height: 56px;
      min-width: 56px;

      @media (max-width: 480px) {
        width: 48px;
        height: 48px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      // &.icons-2 {
      //   display: inline-flex;
      //   position: relative;

      //   img {
      //     position: absolute;
      //     width: 35px;
      //     border: 2px solid white;
      //     border-radius: 50px;

      //     @media (max-width: 480px) {
      //       width: 30.5px;
      //     }
      //   }

      //   .icon {
      //     top: 0;
      //     left: 0;
      //   }
      // }
    }

    &-name {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      color: $colorNeutral900;

      display: inline-block;
      margin-left: 16px;

      @media (max-width: 1376px) {
        font-size: 20px;
      }

      @media (max-width: 480px) {
        line-height: 24px;
      }
    }

    &-apy {
      font-family: Roboto;
      font-weight: bold;
      font-size: 36px;
      line-height: 48px;
      color: $colorNeutral900;

      @media (max-width: 1376px) {
        font-size: 32px;
      }
    }

    &-apr {
      font-family: Roboto;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: $colorNeutral800;

      display: flex;
      align-items: center;
      text-align: end;

      @media (max-width: 1376px) {
        font-size: 18px;
      }

      @media (max-width: 480px) {
        justify-content: center;
      }
    }

    &-stat {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      @media (max-width: 768px) {
        margin: 6px 0;
      }

      @media (max-width: 480px) {
        margin: 18px 0;

        &:first-of-type {
          margin-top: 28px;
        }

        &:last-of-type {
          margin-bottom: 28px;
        }
      }

      &-name {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $colorNeutral700;

        @media (max-width: 1376px) {
          font-size: 14px;
        }
      }

      &-number {
        font-family: Roboto;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: $colorNeutral900;
        text-align: right;

        @media (max-width: 1376px) {
          font-size: 18px;
        }

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &-btn {
      display: none;
      background: $colorPrimary;
      border-radius: 4px;
      border: none;
      padding: 20px 0;
      width: 100%;

      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: white;

      @media (max-width: 480px) {
        display: block;
      }
    }
  }
}
