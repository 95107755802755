@import './variable.scss';

html {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    @extend %scrollbar;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    @extend %scrollbar-thumb;
  }
}

body {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none !important;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

@import './common.scss';
@import './home.scss';
@import './dialog.scss';
@import './dashboard.scss';
@import './table.scss';
@import './lending_home.scss';
@import './coming_soon.scss';
@import './portfolio.scss';
@import './chart.scss';
@import './coin.scss';
@import './lib.scss';
@import './market_analysis.scss';
@import './sidebar.scss';
@import './faq.scss';
@import './vault.scss';
@import './price.scss';
@import './pagination.scss';
@import './vault-detail.scss';
@import './liquidate.scss';
@import './not_found.scss';
@import './bridge.scss';
@import './bridge_detail.scss';
@import './switch_network.scss';
@import './vault_nft.scss';
@import './the_countdown.scss';
