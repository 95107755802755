.coming-soon {
  background: $colorNeutral100;
  box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  margin-top: 20px;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: calc(100vh - 170px);
    .coming-box {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .text-coming {
        padding-top: 1rem;
        color: $colorNeutral900;
        opacity: 0.6;
      }
    }
  }
}
