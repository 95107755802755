.text-error {
  color: $colorRedApp;
}
.text-accent {
  color: $colorFooter;
}

.content-app {
  .content-app-left {
    @media (max-width: 1024px) {
      display: none;
      background-color: $colorWhite;
    }
    background: $colorSideBar;
    width: 224px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
  .content-app-right {
    margin-left: 224px;
    height: 100%;
    padding-top: 20px;
    padding-left: 32px;
    padding-right: 32px;

    @media (max-width: 1024px) {
      margin-left: 0px;
    }

    @media (max-width: 576px) {
      padding-top: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .status-bar {
      z-index: 99;
      position: relative;
      background: $colorWhite;
      @media (max-width: 576px) {
        margin-left: -32px;
        margin-right: -32px;
        padding: 0 32px 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 16px 16px;
      }

      @media (max-width: 576px) {
        margin-left: -8px;
        margin-right: -8px;
        padding: 0 16px 8px;
      }

      .warning-connect {
        display: none;
        &--active {
          display: block;
          text-align: center;
          color: #f35454;
        }
      }

      .menu-mobile {
        display: none;
        .icon-menu-hamberger {
          cursor: pointer;
          margin-right: 20px;
        }
      }
      .title-page {
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 3rem;
        color: $colorNeutral900;
        display: flex;
        align-items: center;
        .icon-title {
          margin-right: 12px;
        }
        @media (max-width: 1024px) {
          display: none;
        }
      }
      .total-value {
        &__title {
          color: $colorTextBlue;
          font-size: 0.75rem;
          font-weight: 500;
        }
        .value {
          .text-value {
            font-family: 'Roboto', sans-serif;
            color: $colorText;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.75rem;
          }
          .percent {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            margin-left: 8px;
            .icon-up-down {
              margin-right: 4px;
            }
            &.up {
              color: $colorGreenPercent;
            }
            &.down {
              color: $colorError400;
            }
          }
        }
      }

      .btn-connect-wallet-wrapper {
        position: relative;
      }

      .btn-connect-wallet,
      .btn-connect-wallet-connected {
        position: relative;
        cursor: pointer;
        background: $colorWhite;
        border: 1px solid $colorNeutral300;
        border-radius: 61px;
        padding: 8px;
        .icon-connect {
          img {
            width: 32px;
            height: 32px;
            background: radial-gradient(
              178.91% 143.12% at -35.42% -35.42%,
              #65aca9 0%,
              #001692 100%
            );
            border-radius: 50%;
          }
        }
        .btn-content,
        .btn-content-connected {
          margin-left: 1rem;
          padding-right: 1rem;
          .status {
            line-height: 12px;
            .icon-status {
              margin-right: 4px;
              &.connected {
                circle {
                  fill: #00ff19;
                }
              }
            }
            .text-status {
              font-size: 0.75rem;
            }
          }
          .text-connect,
          .mobile-text-connect {
            color: $colorPrimary;
            font-weight: bold;
            line-height: 1.25rem;
          }
        }

        @media (max-width: 576px) {
          .btn-content-connected {
            display: none;
          }
          .btn-content {
            margin-left: 8px;
            padding-right: 8px;
            .status {
              display: none !important;
            }
          }
        }

        &-popup {
          cursor: default;
          position: absolute;
          top: calc(100% + 4px);
          right: 0;

          padding: 24px;
          background: #ffffff;
          border-radius: 16px;
          box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
            0px 10px 32px -4px rgba(15, 40, 61, 0.1);
          z-index: 100;
          border: 1px solid $colorNeutral300;
          background: $colorNeutral200;

          .line {
            width: 100%;
            height: 2px;
            background: linear-gradient(134.72deg, #6fff00 0%, #005bff 65.74%);
            margin-top: 8px;
          }

          .popup {
            color: $colorNeutral900;

            &-title {
              font-weight: 600;
              font-size: 24px;
              line-height: 28px;
              display: flex;
              align-items: center;
              .icon {
                margin-left: 10px;
              }
            }

            &-stat {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              margin-top: 16px;
            }

            &-value {
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              margin-top: 4px;
            }

            &-btn {
              cursor: pointer;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: $colorPrimary;

              background: $colorNeutral300;
              border: none;
              border-radius: 12px;
              width: 100%;
              padding: 18px 55px;
              margin-top: 24px;
              white-space: nowrap;

              display: flex;
              justify-content: center;
              align-items: center;

              &:last-of-type {
                margin-top: 8px;
              }

              svg {
                margin-right: 12px;
              }

              &-copy {
                cursor: pointer;
              }
            }

            &-address {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
      @media (max-width: 576px) {
        .btn-connect-wallet-connected {
          border: none;
          padding: 0;
        }
      }
      .btn-connect-wallet {
        box-shadow: 0px 8px 12px rgba(18, 49, 150, 0.27);
        border: none;

        .mobile-text-connect {
          display: none;
        }

        @media (max-width: 576px) {
          border: 1px solid $colorNeutral300;
          box-shadow: none;
          padding: 4px;
          .text-connect {
            display: none;
          }
          .mobile-text-connect {
            display: block;
            font-weight: 500 !important;
          }
        }

        @media (max-width: 325px) {
          border: 0;
          .mobile-text-connect {
            display: none;
          }
          .btn-content {
            padding: 0;
            margin: 0;
          }
        }
      }
      .wrong-network {
        box-shadow: 0px 8px 12px rgba(18, 49, 150, 0.27);
        border: 1px solid $colorError400;
        background: $colorBgError;
        position: relative;
        cursor: pointer;
        border-radius: 61px;
        padding: 8px;
        .btn-content {
          .text-connect {
            color: $colorError400;
            font-weight: bold;
          }
        }
      }
      .btn-claim-rifi {
        margin-right: 8px;
        cursor: pointer;
        background: $colorWhite;
        border: 1px solid $colorNeutral300;
        border-radius: 61px;
        padding: 4px 4px 4px 8px;
        .number-coin {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: $colorNeutral900;
          margin-right: 8px;
        }
        .logo-rifi {
          width: 32px;
          height: 32px;
          object-fit: cover;
          border-radius: 50%;
        }

        @media (min-width: 576px) {
          padding: 8px 8px 8px 16px;
        }
      }
    }
    @media (max-width: 1024px) {
      .status-bar {
        .total-value {
          display: none;
        }
        .menu-mobile {
          display: block;
        }
      }
      @media (max-width: 1024px) {
        .status-bar {
          .total-value {
            display: none;
          }
        }
      }
    }
  }
}

.sidebar-modal {
  .modal-dialog {
    width: 75%;
    height: 100%;
    max-width: 300px;
    margin: 0;
    padding: 0;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
    -o-transition: opacity 0.3s linear, left 0.3s ease-out;
    transition: opacity 0.3s linear, left 0.3s ease-out;
    .modal-content {
      height: 100%;
      .menu-sidebar--true {
        display: flex;
        flex-direction: column;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        background: white;
        .sidebar-menu-items {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .menu-sidebar__branding {
          margin-top: 20px;
          margin-bottom: 40px;
          margin-left: 32px;
        }
        .nav-sidebar__item {
          padding: 12px 5px 12px;
          display: flex;
          align-items: center;
          @media (max-height: 667px) {
            padding: 8px 5px 8px;
          }
        }
        .social-media {
          display: flex;
          margin-top: 10px;
          margin-bottom: 10px;
          justify-content: flex-start;
          &__icon {
            padding-right: 50px;
          }
        }
      }
    }
  }
}

.modal-open {
  padding-right: 0 !important;
  .modal {
    @media (max-width: 576px) {
      &.sidebar-modal {
        padding: 0rem !important;
      }
      padding: 0.5rem !important;
    }
  }
}

.claim-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 20px 20px;
        align-items: center;
        justify-content: center;
        background: $colorNeutral200;
        border-radius: 16px 16px 0px 0px;
        .title-claim-modal {
          text-align: center;
          font-weight: 600;
          color: $colorNeutral900;
          font-size: 1.25rem;
        }
        .close-btn {
          cursor: pointer;
          position: absolute;
          right: 20px;
          top: 14px;
        }
      }
    }
  }
  .modal-body {
    padding: 16px 28px 36px 28px;
    @media screen and (max-width: 576px) {
      padding: 8px;
    }
  }
  &__content {
    .info-token {
      text-align: center;
      .coin-logo {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        .img-coin {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .balance {
        padding-top: 10px;
        font-weight: bold;
        color: $colorNeutral900;
        line-height: 1.75rem;
        font-family: 'Roboto', sans-serif;
      }
      .total-monney {
        color: $colorNeutral700;
        font-weight: 500;
        line-height: 1.25rem;
        font-family: 'Roboto', sans-serif;
      }
    }
    .claim-info {
      padding-top: 12px;
      &__item {
        padding: 12px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $colorNeutral300;
        .label {
          color: $colorNeutral800;
          line-height: 1.5rem;
        }
        .value {
          color: $colorNeutral900;
          font-weight: 500;
          font-family: 'Roboto', sans-serif;
        }
        &:last-child {
          border-bottom: none;
        }
        &.contract {
          border-bottom: none;
          .value {
            color: $colorPrimary400;
            margin-right: 14px;
          }

          .copy-address {
            position: relative;
            &:hover {
              .tooltiptext {
                visibility: visible;
                opacity: 1;
              }
            }
            .tooltiptext {
              visibility: hidden;
              width: 140px;
              background-color: $colorPrimary400;
              color: $colorWhite;
              text-align: center;
              border-radius: 6px;
              padding: 5px;
              position: absolute;
              z-index: 1;
              bottom: 150%;
              right: 0;
              opacity: 0;
              transition: opacity 0.3s;
              &::after {
                content: '';
                position: absolute;
                top: 100%;
                right: 10%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: $colorPrimary400 transparent transparent
                  transparent;
              }
            }
            .icon-copy {
              cursor: pointer;
            }
          }
        }
      }
    }
    .add-token-box {
      border-bottom: 1px solid $colorNeutral300;
      padding-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-add-token {
        box-shadow: none;
        background: transparent;
        color: $colorPrimary400;
        border: 1px solid $colorPrimary400;
        border-radius: 8px;
        font-weight: 600;

        &:hover {
          background: transparent;
          color: $colorWhite;
          background: $colorPrimary400;
        }
        &:focus {
          color: $colorPrimary400;
          background: transparent;
        }
        &:active {
          background: transparent;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .claim-btn-box {
      padding-top: 30px;
      .btn-claim {
        width: 100%;
        background: $colorPrimary400;
        border: none;
        color: $colorWhite;
        font-weight: 600;
        padding: 20px 0px;
        border-radius: 8px;
        box-shadow: none;
        &:hover {
          background: darken($color: $colorPrimary400, $amount: 5);
        }
        &:focus {
          background: $colorPrimary400;
        }
        &:active {
          background: $colorPrimary400;
          &:focus {
            box-shadow: none;
          }
        }
        &.disable {
          background: $colorNeutral300;
          color: $colorNeutral500;
        }
      }
    }
  }
}

.rifi-tooltip .tooltip-inner {
  max-width: 300px;
}
