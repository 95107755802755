.the-countdown {
  // margin: 3rem 0;
  // height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-family: Fira Sans Condensed;
    font-style: italic;
    font-weight: 800;
    font-size: 2rem;
    line-height: 100%;
    /* or 72px */

    text-align: center;
    text-transform: uppercase;

    /* Logo */
  }

  .subtitle {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  ul {
    list-style: none;
    display: flex;
    padding: unset;
    margin: unset;
    li {
      margin: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      span:first-child {
        font-family: "Bebas Neue", cursive;
        font-weight: normal;
        font-size: 6rem;
      }
      span:last-child {
        font-size: 1rem;
      }
      .dot {
        line-height: 150px;
      }
    }
  }

  .see-more {
    margin-top: 3rem;
    margin-bottom: 2rem;
    a {
      border-radius: 6px;
      padding: 0.8rem 1.5rem;
      transform: skewX(-10deg);
      border: none;
      text-decoration: none;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }

  .video-responsive {
    margin-top: 2rem;
  }
}

@media (max-width: 992px) {
  .the-countdown {
    .video-responsive {
      margin-top: 2rem;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      width: 100%;
    }

    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

@media (max-width: 556px) {
  .the-countdown {
    margin-top: 2rem;
    .title {
      font-size: 1.5rem;
      text-align: center;
    }
    .subtitle {
      font-size: 1rem;
      text-align: center;
    }
    ul {
      li {
        span:first-child {
          font-size: 3.5rem;
        }
        span:last-child {
          color: #aeaeae;
          font-size: 0.7rem;
        }
        .dot {
          line-height: 80px;
        }
      }
    }
  }
}
