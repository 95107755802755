.dashboard-wrap {
  .title {
    color: $colorFooter;
  }
  .btn-wrap-toggle {
    background: transparent;
    border: none;
  }
  .dashboard-box {
    position: relative;
    padding-top: 2rem;
  }
  &__header {
    position: relative;
    background: $colorDashboard;
    box-shadow: 0px 16px 24px rgba(53, 64, 166, 0.25);
    border-radius: 24px;
    .connected {
      // height: 400px;
      border-radius: 24px;
      background: url('../images/bg_dashboard_decor.svg'),
        url('../images/bg_dashboard_connected.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: left top, center, left bottom, right bottom;
      @media (max-width: 992px) {
        background: none;
      }

      .overview_title {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 576px) {
          display: none;
        }
        .title-text {
          padding: 5px 75px;
          background: url('../images/bg_top_decor.svg');
          background-repeat: no-repeat;
          background-position: top left;
          background-size: 100%;
          color: $colorWhite;
          text-transform: uppercase;
          font-size: 0.75rem;
          font-weight: bold;
        }
      }
      .overview-box {
        display: flex;
        justify-content: center;
        @media (max-width: 786px) {
          display: none;
        }
        .overview_content {
          width: 100%;
          max-width: 1152px;
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
          padding: 20px 0px;
          .balance {
            width: fit-content;
            text-align: center;
            font-weight: 600;
            color: $colorWhite;
            .icon {
              padding-top: 28px;
              padding-bottom: 16px;
              width: 28px;
            }
            .balance-title {
              font-size: 14px;
              line-height: 16px;
              padding-bottom: 6px;
              font-weight: 500;
            }
            .balance-token {
              font-size: 24px;
              line-height: 28px;
              font-weight: 600;
              padding-bottom: 4px;
              &.type-small {
                font-size: 20;
              }
            }
            .number-assets {
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              opacity: 0.4;
            }

            &.supply-balance {
              margin-right: 10%;
              margin-left: auto;
            }

            &.borrow-balance {
              margin-left: 10%;
            }
          }
          .net-apy {
            color: $colorWhite;
            position: relative;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: 220px;

            .img-bg {
              position: absolute;
              margin: auto;
              max-width: 320px;
              width: 100%;
              height: 100%;
              left: 0;
              right: 0;
            }
            .net-apy-text {
              .title {
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                color: $colorWhite;
              }
              .percent {
                font-size: 36px;
                line-height: 48px;
                font-weight: 600;
              }
              .up_down {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                img {
                  margin-right: 4px;
                }
                &.up {
                  color: $colorTextGreen;
                }
              }
            }
          }
        }
      }
      .overview-content-media {
        display: none;
        @media (max-width: 786px) {
          display: block;
          padding-top: 24px;
        }
        .net-apy {
          color: $colorWhite;
          position: relative;
          text-align: center;
          // padding: 24px;
          .img-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
          }
          .net-apy-text {
            padding: 135px;
            @media (max-width: 576px) {
              padding: 20%;
            }
            .title {
              font-size: 1rem;
              font-weight: 600;
              color: $colorWhite;
              @media (max-width: 576px) {
                font-size: 0.875rem;
              }
            }
            .percent {
              font-size: 4rem;
              font-weight: bold;
              line-height: 4.5rem;
              @media (max-width: 576px) {
                font-size: 2rem;
              }
            }
            .up_down {
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              img {
                margin-right: 4px;
              }
              &.up {
                color: $colorTextGreen;
              }
            }
          }
        }
        .row-balance {
          display: flex;
          justify-content: space-around;
          margin-top: -24%;
          padding-bottom: 10px;
          .balance {
            // flex-grow: 1;
            font-weight: 600;
            color: $colorWhite;
            .icon {
              padding-top: 40px;
              padding-bottom: 24px;
            }
            .balance-title {
              font-size: 1.125rem;
              line-height: 1.8rem;
              padding-bottom: 7px;
              @media (max-width: 576px) {
                font-size: 0.875rem;
                line-height: 1.5rem;
              }
            }
            .balance-token {
              font-size: 2.5rem;
              line-height: 4.5rem;
              padding-bottom: 10px;
              @media (max-width: 992px) {
                font-size: 2.5rem;
                line-height: 2.25rem;
              }
              @media (max-width: 768px) {
                font-size: 2rem;
                line-height: 2.25rem;
              }
              @media (max-width: 576px) {
                font-size: 1.5rem;
                line-height: 2.25rem;
              }
              @media (max-width: 465px) {
                font-size: 1rem;
                line-height: 2.25rem;
              }
            }
            .number-assets {
              font-size: 1.25rem;
              opacity: 0.4;
              @media (max-width: 576px) {
                font-size: 1rem;
                line-height: 1rem;
              }
            }
            &.supply-balance {
              text-align: left;
            }
            &.borrow-balance {
              text-align: right;
            }
          }
        }
        .overview-footer-media {
          border-radius: 24px;
          padding: 1.2rem;
          background: rgba(72, 81, 173, 0.8);
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: $colorWhite;
          .receive-apy-text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            .d-flex {
              align-items: center;
            }
            .icon-rifi {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              object-fit: cover;
            }
            .text-content {
              @media (max-width: 396px) {
                margin-top: 0;
              }
            }
          }
          .borrow-limit-content {
            .borrow-limit__title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 700;
              font-size: 14px;
              padding-bottom: 8px;
            }
            .progress {
              height: 12px;
              background: $colorDashboard;
              border-radius: 22px;
              border: 1px solid rgba(255, 255, 255, 0.2);
              .progress-bar {
                background: $colorYellowProgess;
                border-radius: 22px;
              }
            }
          }

          @media (max-width: 576px) {
            border-radius: 0;
          }
        }
      }
      .overview-footer {
        color: $colorWhite;
        @media (max-width: 786px) {
          display: none;
        }
        .receive-apy {
          background-image: url('../images/bg_dashboard_3.svg');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: right top;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 14px 0 14px 24px;
          width: 48%;
          height: 60px;
          border-bottom-left-radius: 24px;
          .receive-apy-text {
            display: flex;
            width: 80%;
            align-items: center;
            .icon-rifi {
              margin-right: 8px;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              object-fit: cover;
            }
            .text-content {
              font-size: 14px;
              line-height: 16px;
              font-weight: 600;
            }
          }
        }
        .borrow-limit {
          background-image: url('../images/bg_dashboard_4.svg');
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          bottom: 0;
          right: 0;
          padding: 14px 24px 14px 0;
          width: 48%;
          height: 60px;
          border-bottom-right-radius: 24px;
          .borrow-limit-content {
            width: 80%;
            float: right;
            .borrow-limit__title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 6px;
              font-size: 14px;
              line-height: 16px;
              font-weight: 500;
            }
            .progress {
              height: 12px;
              background: $colorDashboard;
              border-radius: 22px;
              border: 1px solid rgba(255, 255, 255, 0.2);
              .progress-bar {
                background: $colorYellowProgess;
                border-radius: 22px;
              }
            }
          }
        }
      }
    }
  }
  &__overview {
    background-image: url('../images/bg_dasboard.svg'),
      url('../images/bg_dashboard_2.svg');
    background-position: top left, top right;
    background-repeat: no-repeat;
    padding: 46px 46px 28px 46px;
    border-radius: 24px;
    .overview-box {
      justify-content: space-around;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
    }
    .apy {
      height: 100%;
      display: flex;
      align-items: center;
      .apy-percent {
        font-weight: bold;
        font-size: 4rem;
        color: $colorWhite;
        background-image: url('../images/bg_apy.svg');
        background-repeat: no-repeat;
        background-position: top right;
        line-height: 5.25rem;
        width: 191px;
        height: 189px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-right: 120px;
      }
      .apy-text {
        color: $colorWhite;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.8rem;
        margin-left: 30px;
        div {
          width: 75%;
        }
      }
    }
    .connect-wallet-box {
      padding-left: 76px;
      .icon-wallet {
        margin-right: 10px;
        margin-left: -6px;
      }
      .title-text {
        text-transform: uppercase;
        color: $colorWhite;
        opacity: 0.4;
        font-weight: bold;
      }
      .description {
        color: $colorWhite;
        font-weight: bold;
        font-size: 2rem;
        .text-blue {
          color: $colorTextLightBlue;
        }
      }
      .btn-box {
        margin-left: -4px;
        margin-right: -4px;
        .btn-custom {
          border-radius: 12px;
          color: $colorWhite;
          font-weight: 600;
          line-height: 1.5rem;
          padding: 12px 24px;
          border: none;
          border-color: transparent;
          min-width: 176px;
          margin: 0px 4px;
          &.btn-connect {
            background: linear-gradient(134.72deg, #6fff00 0%, #005bff 65.74%);
          }
          &.btn-learnmore {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }
    }
    .top-assets {
      display: flex;
      align-items: center;
      padding-top: 48px;
      .title {
        padding-right: 50px;
        color: $colorWhite;
        .text-weight {
          font-weight: 600;
          white-space: nowrap;
        }
      }
      .list-token-box {
        overflow: hidden;
        text-align: left;
        width: 100%;
        &:hover {
          .list-token {
            cursor: pointer;
            animation-play-state: paused;
          }
        }
        .list-token {
          display: inline-block;
          white-space: break-spaces;
          position: relative;
          @media (max-width: 576px) {
            white-space: nowrap;
            transform: translateX(-50%);
            left: 50%;
            -webkit-animation: movement-smooth 15s linear infinite;
            animation: movement-smooth 15s linear infinite;
          }
          @keyframes movement-smooth {
            from {
              transform: translateX(0);
              left: 100%;
            }
            to {
              transform: translateX(-100%);
              left: 0;
            }
          }
          .token-item {
            padding: 4px 38px 4px 19px;
            display: inline-flex;
            align-items: center;
            .logo-token {
              // border: 2px solid rgba(255, 255, 255, 0.32);
              // border-radius: 50%;
              // width: 50px;
              // height: 50px;
              // img {
              //   width: 100%;
              //   height: 100%;
              // }
              margin-right: 8px;
              .img-logo {
                width: 40px;
                height: 40px;
                object-fit: cover;
                min-width: 32px;
              }
            }
            .name {
              color: $colorWhite;
              opacity: 0.5;
              font-size: 0.85rem;
              line-height: 1.25rem;
              font-weight: 600;
              padding-left: 3px;
            }
            .percent {
              line-height: 1.25rem;
              color: $colorGreenPercent;
              font-weight: 600;
            }
          }
        }
      }
    }
    @media (max-width: 1400px) {
      .connect-wallet-box {
        padding-left: 0px;
        .btn-box {
          .btn-custom {
            margin-bottom: 10px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      background-image: none;
      .apy {
        flex-direction: column;
        .apy-text {
          div {
            width: 100%;
          }
          margin-left: auto !important;
          margin: 15px 0 15px auto;
        }
      }
      .connect-wallet-box {
        padding-left: 0px;
        margin-top: 34px;
        .description {
          text-align: center;
        }
      }
      .btn-box {
        margin-top: 20px;
      }
      .top-assets {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    @media (max-width: 560px) {
      padding: 32px 24px 24px;
      .apy {
        .apy-percent {
          font-size: 3.5rem;
        }
        .apy-text {
          font-size: 20px;
          margin: 5px 0 5px auto;
        }
      }
      .connect-wallet-box {
        margin-top: 24px;
        .title-text {
          font-size: 14px;
        }
        .description {
          font-size: 24px;
        }
        .btn-box {
          text-align: center;
          .btn-connect {
            width: 80%;
            margin: 0 0 10px;
          }
        }
      }

      .top-assets {
        margin: 24px -24px -24px;
        padding: 24px;
        background: rgba(72, 81, 173, 0.8);
      }
    }

    @media (max-width: 375px) {
      padding: 12px 8px;
      .connect-wallet-box {
        .description {
          font-size: 20px;
        }
      }
      .top-assets {
        margin: 24px -8px -12px;
        padding: 12px 8px;
      }
    }
  }
  &__content {
    margin-top: 3.5rem;
    min-height: 30rem;
    .comingsoon-content {
      @media (max-width: 576px) {
        .coming-row {
          margin-left: -8px;
          margin-right: -8px;
        }
        .coming-col {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    .table-dashboard-row {
      @media (max-width: 576px) {
        display: none;
      }
    }
    .col-table-box {
      margin-bottom: 20px;
      .table-box {
        background: $colorWhite;
        color: $colorText;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.18);
        border-radius: 1.25rem;
        text-transform: capitalize;
        padding: 26px 24px 16px 24px;
        .title-table {
          font-size: 1.5rem;
          line-height: 2.25rem;
          margin-left: 1rem;
          font-weight: 600;
          color: $colorTextBlack;
        }
        .table-market {
          .col-markets {
            .symbol {
              display: none;
            }
          }
          .col-item-table {
            .wallet {
              color: $colorText;
              font-weight: 500;
            }
            .apy {
              color: $colorText;
              font-weight: 500;
            }
          }
        }
      }
      .supply-wap,
      .borrow-wap {
        background: $colorWhite;
        box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
        border-radius: 24px;
        .bg-table-supplied {
          // margin-bottom: 50px;
          box-shadow: none;
          border-radius: 24px;
          background: radial-gradient(
              392.46% 392.46% at -24.46% -293.85%,
              #84ff82 0%,
              rgba(27, 118, 255, 0) 100%
            ),
            #2d379b;
          color: $colorWhite;
          padding-bottom: 0;
          .table-wrap {
            min-height: unset;
            .title-table {
              color: $colorWhite;
              text-transform: none;
            }
            .preview-token {
              margin-left: auto;
              .avatar-market {
                width: 24px;
                height: 24px;
                margin-right: 8px;
              }
              .addition-avatar-market {
                width: 24px;
                height: 24px;
                background: white;
                border-radius: 50%;
                color: $colorPrimary;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
              }
            }
            .table-market-th {
              color: #f8f9f9;
            }
            .label-large {
              color: $colorWhite;
            }
            .label-small {
              font-family: 'Roboto', sans-serif;
              white-space: nowrap;
              &.apy {
                color: $colorWhite;
              }
              &.wallet {
                font-weight: 500;
                color: $colorNeutral300;
              }
              .bg-progressbar-horizontal {
                min-width: 40% !important;
              }
            }
            .table-market-tr {
              &:hover {
                background: darken($color: #2d379b, $amount: 5);
              }
            }
            .detail-supplied-token-btn {
              width: calc(100% + 48px);
              height: 48px;
              margin-left: -24px;
              margin-top: 16px;
              border-radius: 0 0 22px 22px;
              background: radial-gradient(
                  392.46% 392.46% at -24.46% -293.85%,
                  #84ff82 0%,
                  rgba(27, 118, 255, 0) 100%
                ),
                #2d379b;
              cursor: pointer;
              transition: all 0.3s ease;
              &:hover {
                background: radial-gradient(
                    392.46% 392.46% at -24.46% -293.85%,
                    #84ff82 0%,
                    rgba(27, 118, 255, 0) 100%
                  ),
                  radial-gradient(
                    392.46% 392.46% at -24.46% -293.85%,
                    #2d379b 0%,
                    rgba(27, 118, 255, 0) 100%
                  );
              }
              &.disabled {
                text-transform: none;
                cursor: unset;
                background: $colorNeutral300;
                color: $colorNeutral600;
              }
            }
          }
        }
        .table-box-supply,
        .table-box-borrow {
          box-shadow: none;
          .percent_limit {
            width: 15%;
            .text-limit {
              margin-left: 10px;
            }
            @media (max-width: 768px) {
              .bg-progressbar-horizontal {
                display: none;
              }
              .col-item-table {
                text-align: center;
                .text-limit {
                  width: 100%;
                  margin-left: 0px;
                }
              }
            }
          }
        }
      }
    }
    .table-dashboard-small {
      display: none;
      @media (max-width: 576px) {
        display: block;
      }
      .martket-tabs {
        display: flex;
        padding: 4px;
        background: $colorNeutral100;
        border-radius: 8px;
        .tab {
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.5rem;
          color: $colorNeutral500;
          border-radius: 8px;
          padding: 8px;
          width: 50%;
          text-align: center;
          cursor: pointer;
          &.active {
            background: $colorTabDialog;
            color: $colorWhite;
          }
        }
      }
      .market-content {
        margin-top: 20px;

        .table-box {
          padding: 16px 10px 0;
          border-radius: 16px;

          .detail-supplied-token-btn {
            width: calc(100% + 20px) !important;
            margin-left: -10px !important;
            margin-top: 16px !important;
            border-radius: 0 0 14px 14px !important;
          }

          &.table-market {
            margin-top: 40px;
            box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
              0px 10px 32px -4px rgba(15, 40, 61, 0.1);
          }
          .col-markets {
            .name {
              display: none;
            }
            .symbol {
              display: block;
            }
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .dashboard-box {
      padding-top: 0;
    }
    &__header {
      margin: -16px -8px 0 -8px;
      padding-top: 16px;
      border-radius: 0;
    }
  }

  .bg-limit-progressbar {
    color: $colorVioletApp;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 0;
    max-width: 80%;
    .text-borrow-limit,
    .max-limit-borrow {
      color: #fff;
    }
    .percentage {
      margin-left: 10px;
    }
    .bg-progressbar-horizontal {
      flex: 1 1;
      margin: 0 10px 0 5px;
    }
  }

  .loading-dashboard {
    min-width: 64px;
    min-height: 64px;
    margin-bottom: 10px;
    background: $colorPrimary;
    border-color: $colorPrimary;
    .rotate-circle {
      width: 70.63%;
      height: 70.63%;
      border-width: 4px;
    }
  }
}

.dashboard-coming {
  background: $colorNeutral100;
  box-shadow: inset 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  margin: 0.5rem 0rem;
  &__header {
    background: radial-gradient(
        392.46% 392.46% at -24.46% -293.85%,
        #84ff82 0%,
        rgba(27, 118, 255, 0) 100%
      ),
      #2d379b;
    border-radius: 1.5rem;
    padding: 1.5rem;
    .title {
      color: $colorWhite;
      font-weight: 600;
      font-size: 1.25rem;
      padding-left: 1rem;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    .coming-box {
      padding: 100px 0px;
      text-align: center;
      .text-coming {
        padding-top: 1rem;
        color: $colorNeutral900;
        opacity: 0.6;
      }
    }
  }
}
