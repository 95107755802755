.portfolio {
  font-family: Poppins;

  .status-bar {
    margin-bottom: 40px;

    @media (max-width: 1024px) {
      margin: 0;
    }
  }

  &-title {
    display: flex;
    align-items: center;

    &.title-1 {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    &.title-2 {
      display: none;

      @media (max-width: 1024px) {
        display: flex;
        margin: 24px 0;
      }
    }

    &-text {
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      color: $colorNeutral900;
      padding-left: 12px;

      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &-stats {
    display: flex;

    @media (max-width: 576px) {
      display: block;
    }
  }

  &-stat {
    &-box {
      border: 1px solid #d6d9db;
      border-radius: 12px;
      padding: 36px;
      flex: 1 1;

      text-align: center;

      &:first-of-type {
        background-color: $colorSupply50;
        margin-right: 16px;

        @media (max-width: 576px) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        background-color: $colorBorrow50;
        margin-left: 16px;

        @media (max-width: 576px) {
          margin-left: 0;
          margin-top: 21px;
        }
      }
    }

    &-icon {
      margin-bottom: 16px;
    }

    &-name {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;

      &.supply {
        color: $colorSupply700;
      }

      &.borrow {
        color: $colorBorrow700;
      }
    }

    &-number {
      font-family: Roboto;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;

      &.big {
        font-weight: bold;
        font-size: 48px;
        line-height: 48px;
        margin: 4px 0 24px 0;
      }
    }
  }

  &-chart {
    &-legion {
      display: flex;
      align-content: space-evenly;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        border-top: 1px solid $colorNeutral300;
      }

      &-label {
        display: flex;
        justify-content: center;
        width: 33.3%;

        > div:last-of-type {
          align-self: center;
        }
      }

      &-name {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorNeutral700;
      }

      &-value {
        font-family: Roboto;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: $colorNeutral900;
      }

      &-icon {
        margin: 0 8px 0 4px;
      }

      &-marker {
        width: 16px;
        height: 16px;
        border-radius: 4px;

        &.color-1 {
          background-color: $colorBorrow900;
        }

        &.color-2 {
          background-color: $colorBorrow700;
        }

        &.color-3 {
          background-color: $colorBorrow500;
        }

        &.color-4 {
          background-color: $colorBorrow300;
        }

        &.color-5 {
          background-color: $colorBorrow100;
        }

        &.color-6 {
          background-color: $colorNeutral300;
        }
      }
    }
  }

  &-overview {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, 1fr);
    height: 313px;

    background: #f9f9fb;
    border: 1px solid $colorNeutral300;
    border-radius: 12px;

    @media (max-width: 768px) {
      height: unset;
    }

    > div {
      &:nth-of-type(1) {
        grid-column: 1 / 4;
        grid-row: 1 / 6;

        text-align: center;
        margin: 42px 0 38px 0;
        border-right: 1px solid $colorNeutral300;

        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (max-width: 768px) {
          grid-column: 1 / 7;
          grid-row: 2 / 4;
        }
      }

      &:nth-of-type(2) {
        grid-column: 4 / 7;
        grid-row: 1 / 6;

        max-width: 250px;

        @media (max-width: 768px) {
          grid-column: 7 / 13;
          grid-row: 2 / 4;

          max-width: unset;
          max-height: 250px;
        }
      }

      &:nth-of-type(3) {
        grid-column: 7 / 13;
        grid-row: 1 / 3;

        @media (max-width: 768px) {
          grid-column: 1 / 13;
          grid-row: 1 / 2;

          text-align: center;
        }
      }

      &:nth-of-type(4) {
        grid-column: 7 / 13;
        grid-row: 3 / 6;

        @media (max-width: 768px) {
          grid-column: 1 / 13;
          grid-row: 4 / 6;
        }
      }
    }

    &-stat {
      &-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $colorNeutral900;
        opacity: 0.6;
      }

      &-number {
        font-family: Roboto;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: $colorNeutral900;

        padding-top: 16px;
      }

      &-change {
        font-family: Roboto;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: $colorSuccess400;

        padding-top: 12px;
      }

      &.total-assets {
        padding: 32px 0 15px 30px;
        border-bottom: 1px solid $colorNeutral300;
      }
    }
  }

  &-list {
    background: white;
    box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
      0px 10px 32px -4px rgba(15, 40, 61, 0.1);
    border-radius: 12px;

    // padding-bottom: 12px;
    margin: 28px 0;

    overflow-x: auto;

    &-header {
      display: flex;
      justify-content: space-between;
      position: sticky;
      left: 0;

      &-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: $colorNeutral900;

        margin: 24px 0 28px 24px;
      }
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      min-width: 1090px;
    }

    tr {
      &:nth-of-type(even) {
        td,
        th {
          background: #f9f9fb;
          border-left-color: #f9f9fb;
        }
      }

      &:not(:nth-of-type(1)):hover {
        td {
          background: #eff2f5;
          border-left-color: $colorPrimary;
        }
      }
    }

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $colorPrimary;
      text-align: right;

      padding: 32px 0;

      &:first-of-type {
        text-align: left;
        padding-left: 24px;
        position: sticky;
        left: 0;
        background: white;

        @media (max-width: 1376px) {
          display: block;
          box-shadow: 4px 20px 20px rgba(42, 41, 67, 0.12);
        }
      }

      &:last-of-type {
        padding-right: 24px;
      }
    }

    td {
      font-family: Roboto;
      color: $colorNeutral900;
      font-weight: 500;
      text-align: right;

      padding: 24px 0;

      &:first-of-type {
        font-family: Poppins;
        text-align: left;
        padding-left: 20px;

        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $colorNeutral900;

        // border-left: 8px solid;
        background: white;
        position: sticky;
        left: 0;

        @media (max-width: 1376px) {
          display: block;
          box-shadow: 0px 30px 20px rgba(42, 41, 67, 0.12);
        }
      }

      &:last-of-type {
        padding-right: 24px;
      }
    }

    .token-icon {
      width: 40px;
      margin-right: 8px;
    }

    .table-number {
      &-primary {
        font-size: 16px;
        line-height: 20px;
      }

      &-secondary {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &::-webkit-scrollbar {
      @extend %scrollbar;
    }

    &::-webkit-scrollbar-thumb {
      @extend %scrollbar-thumb;
    }
  }
}
