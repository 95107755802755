.header-home {
  background-image: url(../home/banner_home.png);
  background-repeat: no-repeat;
  background-size: cover;
  .logo-xlending {
    margin-right: 12rem;
    @media (max-width: 576px) {
      margin-right: 3rem;
    }
  }
  &-box {
    padding-bottom: 5rem;
    .silder-box {
      position: relative;
      min-height: 500px;
    }
    .introduce {
      font-size: 3rem;
      line-height: 4rem;
      margin-top: 15%;
      max-width: 80%;
      color: $colorWhite;
      padding-bottom: 45%;
      font-family: 'Lato', sans-serif;
      .color-number {
        color: $colorFooter;
        font-family: 'Aldrich', sans-serif;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
        max-width: 100%;
      }
      @media (max-width: 576px) {
        font-size: 1rem;
        max-width: 100%;
        line-height: 2rem;
      }
    }
    .protocol {
      background: $colorGrey;
      color: $colorWhite;
      padding: 1.5rem 2.25rem;
      font-size: 1.1rem;
      line-height: 2.25rem;
      text-align: center;
      border-radius: 0.5rem;
      margin: 0rem 7rem;
      .btn-docs {
        color: $colorFooter;
        outline: none;
        text-transform: uppercase;
        line-height: 2.25rem;
        font-weight: bold;
        font-size: 1.12rem;
        .arrow-icon {
          margin-left: 0.6rem;
        }
        &:focus {
          box-shadow: none;
          text-decoration: none;
        }
      }
      @media (max-width: 992px) {
        margin: 0rem 2rem;
      }
      @media (max-width: 768px) {
        margin: 0rem 0rem;
      }
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
  }
}
.home-lending {
  .label-box {
    color: $colorHeaderApp;
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin-bottom: 0.5rem;
  }
  .title-box {
    font-weight: bold;
    color: $colorFooter;
    font-size: 1.8rem;
    line-height: 2.25rem;
    margin-bottom: 0.8rem;
  }

  .try-xlending {
    .title-box {
      max-width: 40%;
      @media (max-width: 992px) {
        max-width: 60%;
      }
      @media (max-width: 576px) {
        max-width: 100%;
        font-size: 1.6rem;
      }
    }
    &__tabs {
      .nav-tabs-custom {
        border-bottom: none;
        .nav-link {
          color: $colorHeaderApp;
          font-size: 1.1rem;
          line-height: 2.25rem;
          font-weight: bold;
          border: none;
          border-bottom: 1px solid $colorGrey;
          &.active {
            color: $colorFooter;
            background-color: transparent;
            border-color: transparent;
            border-bottom: 0.25rem solid $colorFooter;
          }
          &:hover {
            color: $colorWhite;
          }
        }
      }
      .tab-content {
        min-height: 600px;
      }
      .institution-col {
        .institution-item {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          color: $colorWhite;
          min-height: 335px;
          display: flex;
          // align-items: center;
          justify-content: center;
          width: 100%;
          &__content {
            width: 50%;
            margin: 0 auto;
            margin-top: 30%;
            .under-line {
              width: 40%;
              height: 0.3rem;
              background-color: $colorWhite;
            }
            @media (max-width: 576px) {
              margin-top: 46%;
            }
          }
          &__name {
            font-size: 1.1rem;
            font-weight: bold;
            margin-bottom: 0.1rem;
            text-transform: capitalize;
            line-height: 2.25rem;
          }
          &__description {
            font-size: 0.75rem;
          }
        }
        @media (max-width: 576px) {
          .institution-item {
            background-size: contain;
          }
        }
        @media (max-width: 768px) {
          .institution-item {
            min-height: 464px;
          }
        }
        &:nth-child(odd) {
          .institution-item {
            background-image: url(../home/bg-coinbase.png);
          }
        }
        &:nth-child(even) {
          .institution-item {
            background-image: url(../home/bg-curv.png);
          }
        }
        &:nth-child(3n + 0) {
          .institution-item {
            background-image: url(../home/bg-bitgo.png);
          }
        }
      }
    }
  }
  .borow {
    padding-top: 3.4rem;
    &__description {
      font-size: 1.1rem;
      line-height: 1.5rem;
      color: $colorHeaderApp;
      padding-bottom: 2.3rem;
    }
    .btn-launch {
      background: transparent;
      color: $colorFooter;
      font-size: 1.1rem;
      border-color: $colorFooter;
      margin-bottom: 2rem;
      .arrow-icon {
        margin-left: 0.6rem;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem;
      }
      &:active {
        background-color: $colorWhite;
        color: $colorFooter;
        border-color: $colorFooter;
      }
      &:hover {
        color: $colorHeaderApp;
        border-color: $colorHeaderApp;
      }
    }
    .list-liquidity {
      color: $colorWhite;
      .liquidity-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $colorBody;
        padding: 1.5rem;
        margin: 0.4rem 0rem;
        border-radius: 1.25rem;
        width: 100%;
        border: 1px solid transparent;
        .coin {
          display: flex;
          align-items: center;
          &__logo {
            min-width: 5rem;
            min-height: 5rem;
            border-radius: 50%;
            margin-right: 0.9rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          &__name {
            color: $colorHeaderApp;
            font-size: 1.1rem;
            line-height: 2.25rem;
          }
          &__acronym {
            color: $colorHeaderApp;
            font-size: 0.8rem;
          }
        }
        .monney-liquidity {
          color: $colorFooter;
          font-weight: bold;
          font-size: 1.5rem;
        }
        &:hover {
          margin-left: 40px;
          border-color: $colorFooter;
          box-shadow: 1.6px 2.4px 30px rgba(255, 210, 64, 0.25);
          transition: all 600ms;
        }
        @media (max-width: 576px) {
          padding: 0.5rem;
          &:hover {
            margin-left: 10px;
          }
          .coin__logo {
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
            min-height: 3rem;
          }
          .coin__name {
            font-size: 0.8rem;
          }
          .coin__acronym {
            font-size: 0.7rem;
          }
          .available {
            font-size: 0.7rem;
          }
          .monney-liquidity {
            font-size: 1rem;
          }
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: center;
      margin-top: 1.875rem;
      margin-bottom: 3rem;
      .btn-view-more {
        background: transparent;
        color: $colorHeaderApp;
        border-color: $colorHeaderApp;
        padding: 0.7rem 2.75rem;
        border-radius: 8px;
        &:hover {
          color: $colorFooter;
          border-color: $colorFooter;
        }
      }
    }
  }
  .block-team {
    background-image: url(../home/bg_team.png);
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    &__box {
      padding: 3rem 0rem;
    }
    .team {
      .team-col {
        padding: 10px;
      }
      &__item {
        text-align: center;
        .avatar {
          width: 10.25rem;
          height: 10.25rem;
          min-width: 10.25rem;
          min-height: 10.25rem;
          border-radius: 50%;
          margin: 0 auto;
        }
        .name {
          font-size: 1.1rem;
          font-weight: bold;
          line-height: 2.25rem;
          color: $colorFooter;
          margin-top: 0.875rem;
          margin-bottom: 0.1rem;
          text-transform: capitalize;
        }
        .position {
          font-size: 0.8rem;
          font-weight: bold;
          line-height: 2.25rem;
          color: $colorHeaderApp;
        }
      }
    }
  }
  .partners {
    color: #fff;
    background: $colorBody;
    padding: 4rem 0rem;
    &__content {
      margin-top: 3rem;
      .partner-col {
        padding: 10px;
      }
    }
  }
  .road-map {
    background-image: url(../home/road_map.png);
    background-repeat: no-repeat;
    background-size: 100% 30%;
    background-position: bottom;
    padding: 2.5rem 0rem;
    .title-box {
      max-width: 45%;
      @media (max-width: 576px) {
        max-width: 100%;
      }
    }
    .img-rocket {
      margin-top: 1.5rem;
      width: 100%;
      height: auto;
    }
    &__item {
      margin-bottom: 4rem;
      .number {
        width: 5rem;
        height: 5rem;
        min-width: 5rem;
        min-height: 5rem;
        background: $colorRoadMap;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2.5rem;
        .text {
          font-size: 1.1rem;
          line-height: 2.5rem;
          font-weight: bold;
          color: $colorBlueDark;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.7rem;
          height: 3.7rem;
          background: $colorRoadMap;
          border-radius: 50%;
        }
      }
      .name {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
      .time {
        margin-top: 0.2rem;
        color: $colorHeaderApp;
        font-size: 0.8rem;
        line-height: 1.1rem;
      }
      &-1 {
        .number {
          background: linear-gradient(
            135deg,
            $colorBlueDark 23.34%,
            $colorBlue 102.05%
          );
        }
        .name {
          color: $colorBlueRoadMap;
        }
      }
      &-2 {
        .number {
          background: linear-gradient(
            135deg,
            $colorBlueLight 18.51%,
            $colorBlueNomal 81.8%
          );
        }
        .name {
          color: $colorBlueLight;
        }
      }
      &-3 {
        .number {
          background: linear-gradient(
            315deg,
            $colorGreen 20.84%,
            $colorGreenLight 93.59%
          );
        }
        .name {
          color: $colorGreenApp;
        }
      }
      &-4 {
        .number {
          background: linear-gradient(
            135deg,
            $colorOrange 16.5%,
            $colorOrangeDark 82.1%
          );
        }
        .name {
          color: $colorOrangeRoadmap;
        }
      }
      &-5 {
        .number {
          background: linear-gradient(
            135deg,
            $colorRedApp 25.11%,
            $colorRedDrak 76.8%
          );
        }
        .name {
          color: $colorRedRoadmap;
        }
      }
    }
    @media (max-width: 765px) {
      .img-col {
        display: none;
      }
    }
    @media (min-width: 768px) {
      .road-map__item {
        margin-bottom: 1.8rem;
        .number {
          width: 3rem;
          height: 3rem;
          min-height: 3rem;
          min-width: 3rem;
          .text {
            width: 2rem;
            height: 2rem;
            font-size: 1rem;
          }
        }
      }
    }
    @media (min-width: 992px) {
      .road-map__item {
        margin-bottom: 2.5rem;
        .number {
          width: 5rem;
          height: 5rem;
          min-height: 5rem;
          min-width: 5rem;
          .text {
            width: 3.7rem;
            height: 3.7rem;
            font-size: 1.1rem;
          }
        }
      }
    }
    @media (min-width: 1200px) {
      .road-map__item {
        margin-bottom: 4rem;
        .number {
          width: 5rem;
          height: 5rem;
          min-height: 5rem;
          min-width: 5rem;
          .text {
            width: 3.7rem;
            height: 3.7rem;
            font-size: 1.1rem;
          }
        }
      }
    }
  }
}
