.table-wrap {
  .table-market {
    &-th {
      color: $colorThTable;
      font-size: 0.875rem;
      line-height: 1.3rem;
      border-top: none;
      border-bottom: none;
      text-align: end;
      white-space: nowrap;
      font-weight: 500;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      &:first-child {
        text-align: left;
      }
    }
    .align-left {
      text-align: start;
    }
    &-td {
      border: none;
      text-align: center;
      vertical-align: middle;
      .col-markets {
        display: flex;
        align-items: center;
        .avatar-market {
          width: 2rem;
          height: 2rem;
          min-width: 2rem;
          min-height: 2rem;
          margin-right: 0.5rem;
        }
        .bg-infor-market {
          .label-large {
            color: $colorText;
            font-weight: 500;
            font-size: 1rem;

            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // max-width: 125px;
          }
          .label-small {
            font-weight: bold;
            font-size: 0.8rem;
            color: $colorHeaderApp;
          }
        }
        .label-white {
          color: $colorWhite;
        }
        .txtLogo {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid $colorWhite;
          color: $colorWhite;
          font-size: 10px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }
      }
      .col-item-table {
        text-align: end;
        .label-large {
          color: $colorFooter;
          font-weight: bold;
          font-size: 0.9rem;
        }
        .label-small {
          font-weight: bold;
          font-size: 0.8rem;
          color: $colorWhite;
          &.up {
            color: $colorGreenApp;
          }
          &.down {
            color: $colorRedApp;
          }
        }
        .negative {
          color: $colorNegative;
        }
        .positive {
          color: $colorPositive;
        }
      }
      &:first-child {
        border-left: 0.3rem solid transparent;
      }
    }
    &-tr {
      cursor: pointer;
      &:hover {
        background: darken($color: $colorWhite, $amount: 5);
        .table-market-td {
          &:first-child {
            border-left: 0.3rem solid $colorYellow;
          }
        }
      }
    }
  }
  .table-responsive {
    &::-webkit-scrollbar {
      @extend %scrollbar;
    }
    &::-webkit-scrollbar-thumb {
      @extend %scrollbar-thumb;
    }
  }
}

.rt-table {
  &::-webkit-scrollbar {
    @extend %scrollbar;
  }
  &::-webkit-scrollbar-thumb {
    @extend %scrollbar-thumb;
  }
}
.rt-tbody {
  &::-webkit-scrollbar {
    @extend %scrollbar;
  }
  &::-webkit-scrollbar-thumb {
    @extend %scrollbar-thumb;
  }
}

.table-bridge {
  border: none;
  border-radius: 24px;
  box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
    0px 10px 32px -4px rgba(15, 40, 61, 0.1);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  &.ReactTable .rt-thead.-header {
    box-shadow: none;
  }
  // &.ReactTable .rt-td {
  //   overflow: unset !important;
  // }
  &.ReactTable .rt-noData {
    background: #f9f9fb;
    top: 77%;
  }

  .rt-table {
    .rt-thead {
      .table-bridge-th {
        color: $colorNeutral900;
        font-weight: 500;
        padding: 32px 5px;
        border: none;
        text-align: left;
        padding-left: 10px;
        &:first-child {
          padding-left: 17px;
        }
      }

      .table-market-td {
        text-align: center;
      }
    }
    .rt-tbody {
      overflow: unset;
      .rt-tr {
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        display: inline-flex;
        align-items: center;
      }

      .rt-tr.-odd {
        background-color: #f9f9fb;
      }

      .row-status {
        padding: 8px 12px;
        border-radius: 5px;
        border: 1px solid #078d58;
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        color: #078d58;
        display: inline-flex;
        align-items: center;
        .text {
          margin-left: 5px;
        }
        &-fail {
          border: 1px solid rgb(146, 15, 15);
          color: rgb(146, 15, 15);
        }
        &-pending {
          border: 1px solid rgb(236, 186, 21);
          color: rgb(236, 186, 21);
        }
      }

      .row-date {
        font-family: Roboto;
        font-weight: 500;
        font-size: 14px;
        color: #34404b;
      }

      .row-time {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #717981;
      }

      .row-type {
        padding: 8px 12px;
        border-radius: 5px;
        border: 1px solid #2963f9;
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
        color: #2963f9;
      }

      .row-link {
        font-family: Roboto;
        font-weight: normal;
        font-size: 14px;
        color: #2963f9;
      }
    }
  }
  .pagination-bottom {
    margin-right: 1rem;
  }
}
