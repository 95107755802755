.content-app {
  // background: url('../home/background_body.png') no-repeat;
  @media (min-width: 1950px) {
    background-size: cover;
  }
  .logo-xlending {
    margin-top: 2.8rem;
    margin-right: 7rem;
    padding-top: 0;
    @media (max-width: 576px) {
      margin-right: 1rem;
    }
  }
  .home-page {
    // background: #000000;
    min-height: calc(100vh - 100px);
    color: $colorFooter;
    padding-bottom: 2rem;
    margin-top: 2rem;
    .loading-spinner {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .title-page {
      &.media {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 3rem;
        color: $colorTextBlack;
        display: block;
        @media (min-width: 1024px) {
          display: none;
        }
      }
    }
    .bg-block1 {
      position: relative;
      padding-top: 10px;
      .market-size-box {
        .title {
          font-weight: 600;
          color: $colorNeutral900;
        }
        .monney-size {
          font-family: 'Roboto', sans-serif;
          color: $colorPrimary;
          font-weight: bold;
          font-size: 3rem;
          @media (max-width: 768px) {
            font-size: 2.5rem;
          }
          @media (max-width: 576px) {
            font-size: 2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          @media (max-width: 400px) {
            font-size: 1.6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .percent {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          font-family: 'Roboto', sans-serif;
          font-size: 1.5rem;
          margin-left: 16px;
          .icon-up-down {
            margin-right: 4px;
          }
          &.up {
            color: $colorTextGreen;
          }
          &.down {
            color: $colorError400;
          }
        }
      }
      .top-markets {
        margin: 36px 0px;
        border: 1px solid $colorNeutral300;
        background: $colorTrTable;
        border-radius: 12px;
        .top-markets-content {
          padding: 24px;
          @media (max-width: 576px) {
            padding: 12px;
          }
          .top-markets-row {
            display: flex;
            .box-left {
              flex: 0 0 33%;
              border-right: 1px solid $colorNeutral300;
              // padding-right: 32px;
              @media screen and (max-width: 1500px) {
                flex: 0 0 45%;
              }
              .change-row {
                display: flex;
                .change-supply-borrow {
                  background: $colorNeutral100;
                  border-radius: 8px;
                  padding: 4px;
                  display: flex;
                  .btn-toggle {
                    background: $colorNeutral100;
                    border: none;
                    color: $colorNeutral500;
                    font-weight: 600;
                    min-width: 160px;
                    border-radius: 4px;
                    padding: 8px 0px;
                    text-align: center;
                    cursor: pointer;
                    &.active {
                      background: $colorPrimary;
                      color: $colorWhite;
                    }
                  }
                }
              }

              .total-volume {
                // padding-top: 46px;
                //style no have total-volume
                padding-top: 24px;
                .title {
                  color: $colorNeutral900;
                  font-weight: 500;
                  line-height: 1.25rem;
                  opacity: 0.6;
                }
                .money {
                  padding: 12px 0px 8px 0px;
                  font-size: 2.25rem;
                  color: $colorNeutral900;
                  line-height: 3rem;
                  font-weight: 600;
                  font-family: 'Roboto', sans-serif;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .percent {
                  font-family: 'Roboto', sans-serif;
                  margin-left: 24px;
                  font-weight: bold;
                  font-size: 1.5rem;
                  .icon {
                    margin-right: 4px;
                  }
                  &.up {
                    color: $colorTextGreen;
                  }
                  &.down {
                    color: $colorRedDown;
                  }
                  @media (max-width: 1200px) {
                    font-size: 1.3rem;
                  }
                  @media (max-width: 576px) {
                    margin-left: 12px;
                    font-size: 1rem;
                  }
                }
              }
            }
            .box-right {
              flex: 0 0 67%;
              @media screen and (max-width: 1500px) {
                flex: 0 0 55%;
              }
              // max-width: 67%;
              // padding-left: 44px;
              // padding-right: 20px;
              //style not include volume - number
              display: flex;
              align-items: center;
              .top3-martket {
                //style not include volume - number
                width: 100%;
                // border-bottom: 1px solid $colorNeutral300;
                padding: 8px 32px 24px 32px;
                .title {
                  font-weight: 500;
                  color: $colorNeutral700;
                  padding-bottom: 14px;
                  line-height: 1.25rem;
                }
                .small-title {
                  background: $colorNeutral300;
                  color: $colorNeutral800;
                  font-weight: 500;
                  border-radius: 4px;
                  padding: 4px;
                  font-size: 12px;
                  line-height: 1rem;
                }
                .list-token {
                  min-height: 48px;
                  display: flex;

                  .coin {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    .avatar-coin {
                      width: 40px;
                      height: 40px;
                      border-radius: 50%;
                      margin-right: 8px;
                    }
                    .name-coin {
                      font-size: 0.75rem;
                      font-weight: 600;
                      color: $colorNeutral700;
                      @media (max-width: 576px) {
                        font-size: 1rem;
                      }
                    }
                    .percent-market {
                      color: $colorNeutral900;
                      font-size: 1.25rem;
                      font-weight: bold;
                      font-family: 'Roboto', sans-serif;
                    }
                  }
                }
              }
              .box-right-bottom {
                display: flex;
                padding: 24px 24px 24px 0px;
                .volume-24,
                .number-supply-borrow {
                  flex-grow: 1;
                  padding: 8px 32px 0px 32px;
                  .title {
                    color: $colorNeutral700;
                    font-weight: 500;
                    padding-bottom: 14px;
                  }
                  .number {
                    color: $colorNeutral900;
                    font-weight: bold;
                    font-size: 1.25rem;
                  }
                }
                .number-supply-borrow {
                  border-left: 1px solid $colorNeutral300;
                }
              }
            }
            @media (max-width: 1200px) {
              flex-direction: column;
              .box-left {
                border-right: none;
                width: 100%;
              }
              .box-right {
                padding: 0;
                width: 100%;
                .top3-martket {
                  padding: 8px 0px 24px 0px;
                }
                .box-right-bottom {
                  padding-right: 0px;
                  .volume-24 {
                    padding-left: 0;
                  }
                }
              }
            }
            @media (max-width: 576px) {
              .box-left {
                width: 100%;
                padding-right: 0;
                .change-row {
                  .change-supply-borrow {
                    .btn-toggle {
                      min-width: 150px;
                    }
                  }
                }

                .total-volume {
                  .money {
                    font-size: 1.5rem;
                  }
                }
              }
              .box-right {
                width: 100%;
                .box-right-bottom {
                  flex-direction: column;
                  padding: 0;
                  .volume-24 {
                    padding-right: 0;
                    padding-bottom: 8px;
                    .title {
                      padding-bottom: 0;
                    }
                  }
                  .number-supply-borrow {
                    .title {
                      padding-bottom: 0;
                    }
                    border-top: 1px solid $colorNeutral300;
                    border-left: none;
                    padding: 8px 0px 0px 0px;
                  }
                }
              }
            }
            @media (max-width: 450px) {
              .box-left {
                width: 100%;
                padding-right: 0;
                .change-row {
                  .change-supply-borrow {
                    width: 100%;
                    .btn-toggle {
                      min-width: 130px;
                      width: 50%;
                    }
                  }
                }
              }
              .box-right {
                .top3-martket {
                  .list-token {
                    flex-wrap: wrap;
                    .coin {
                      padding: 4px 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // .block1 {
    //   width: 100%;
    //   .box-col {
    //     padding: 15px;
    //     .box {
    //       min-height: 473px;
    //       border-radius: 20px;
    //       background: $colorBoxMarket;
    //       width: 100%;
    //       display: inline-block;
    //       padding: 20px 24px;
    //       box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.18);
    //       .box-common {
    //         &__header {
    //           font-size: 1.5rem;
    //           line-height: 3rem;
    //           color: $colorWhite;
    //           font-weight: 600;
    //         }
    //         &__content {
    //           padding: 2.75rem 0rem;
    //           .total-money {
    //             font-family: 'Roboto', sans-serif;
    //             font-weight: bold;
    //             font-size: 2rem;
    //             line-height: 48px;
    //             color: $colorWhite;
    //             @media (max-width: 1200px) {
    //               font-size: 1.6rem;
    //             }
    //             @media (max-width: 576px) {
    //               font-size: 1.3rem;
    //             }
    //           }
    //           .percent {
    //             font-family: 'Roboto', sans-serif;
    //             margin-left: 24px;
    //             font-weight: bold;
    //             font-size: 1.5rem;
    //             .icon {
    //               margin-right: 4px;
    //             }
    //             &.up {
    //               color: $colorTextGreen;
    //             }
    //             &.down {
    //               color: $colorRedDown;
    //             }
    //             @media (max-width: 1200px) {
    //               font-size: 1.3rem;
    //             }
    //             @media (max-width: 576px) {
    //               margin-left: 12px;
    //               font-size: 1rem;
    //             }
    //           }
    //           .top-markets {
    //             &__title {
    //               margin: 24px 0px;
    //               font-size: 1rem;
    //               line-height: 1.5rem;
    //               color: $colorWhite;
    //               opacity: 0.6;
    //             }
    //             &__item {
    //               line-height: 3rem;
    //               margin-bottom: 0.19rem;
    //             }
    //             .coin {
    //               display: flex;
    //               align-items: center;
    //               .avatar-coin {
    //                 width: 40px;
    //                 height: 40px;
    //                 border-radius: 50%;
    //                 margin-right: 8px;
    //               }
    //               .name-coin {
    //                 font-size: 1.25rem;
    //                 font-weight: 600;
    //                 color: $colorWhite;
    //                 @media (max-width: 576px) {
    //                   font-size: 1rem;
    //                 }
    //               }
    //             }

    //             .percent-market {
    //               font-family: 'Roboto', sans-serif;
    //               color: $colorWhite;
    //               font-weight: 600;
    //               font-size: 1rem;
    //             }
    //           }
    //           .progess-container {
    //             width: 100%;
    //             height: 100%;
    //             .progess-bar-box {
    //               width: 100%;
    //               height: 1.75rem;
    //               font-weight: bold;
    //               border-radius: 20px;
    //               background: $colorProgess;
    //             }
    //           }
    //         }
    //         &__footer {
    //           display: flex;
    //           justify-content: space-between;
    //           &--title {
    //             color: $colorWhite;
    //             line-height: 1.5rem;
    //             opacity: 0.6;
    //           }
    //           &--content {
    //             font-family: 'Roboto', sans-serif;
    //             margin-top: 1rem;
    //             color: $colorWhite;
    //             font-weight: bold;
    //             font-size: 1.5rem;
    //             line-height: 1.75rem;
    //             @media (max-width: 576px) {
    //               font-size: 1.2rem;
    //             }
    //           }
    //           .volume {
    //             flex-direction: column;
    //             text-align: left;
    //           }
    //           .number {
    //             flex-direction: column;
    //             align-items: flex-end;
    //             text-align: right;
    //           }
    //         }
    //         &.box-supply {
    //           .top-markets {
    //             .progess-bar-box {
    //               width: 100%;
    //               height: 1.5rem;
    //               border-radius: 32px;
    //               background: $colorProgess;
    //               .progress-bar {
    //                 border-radius: 32px;
    //                 background: $colorProgessSupply;
    //               }
    //             }
    //           }
    //         }
    //         &.box-borrow {
    //           .top-markets {
    //             .progess-bar-box {
    //               width: 100%;
    //               height: 1.5rem;
    //               border-radius: 32px;
    //               background: $colorProgess;
    //               .progress-bar {
    //                 border-radius: 32px;
    //                 background: $colorProgessBorrow;
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .market-box {
      margin-top: 36px;
      box-shadow: 0px 5px 14px -6px rgba(15, 40, 61, 0.12),
        0px 10px 32px -4px rgba(15, 40, 61, 0.1);
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      .market-box-header {
        padding: 24px 24px 11px 24px;
        .market-box__title {
          font-weight: 600;
          line-height: 2.25rem;
          font-size: 1.5rem;
          color: $colorTextBlack;
          margin-bottom: 32px;
        }
        .change-unit-row {
          margin-bottom: 32px;
          display: flex;
          .change-unit {
            background: $colorNeutral100;
            padding: 4px;
            border-radius: 8px;
            display: flex;
            .btn-unit {
              width: 50%;
              background: $colorNeutral100;
              border: none;
              color: $colorNeutral500;
              font-weight: 600;
              min-width: 150px;
              border-radius: 4px;
              padding: 7px 0px;
              text-align: center;
              cursor: pointer;
              &.active {
                background: $colorPrimary;
                color: $colorWhite;
              }
            }
            @media (max-width: 450px) {
              width: 100%;
              .btn-unit {
                min-width: 120px;
                width: 50%;
              }
            }
          }
        }
      }

      .box-table {
        .table-market-th {
          color: $colorPrimary400;
          font-weight: 500;
          &.collapse.w-50 {
            width: 50px !important;
          }
          &.w-100 {
            width: 100px !important;
          }
        }
        .table-market-td {
          &.collapse.w-50 {
            width: 50px !important;
            .col-markets {
              .bg-infor-market {
                display: none;
              }
            }
          }
          &.w-100 {
            width: 100px !important;
          }
          .col-markets {
            width: 100%;
            .bg-infor-market {
              width: 80%;
              .label-large {
                color: $colorText;
                font-weight: normal;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: start;
              }
              .label-small {
                color: $colorTextGrey;
                font-weight: normal;
                text-align: start;
              }
              @media (max-width: 576px) {
                .label-large {
                  display: none;
                }
                .label-small {
                  font-weight: 500;
                  color: $colorText;
                }
              }
            }
            &.collapse {
              .bg-infor-market {
                display: none;
              }
            }
          }

          .col-item-table {
            .label-large {
              font-family: 'Roboto', sans-serif;
              color: $colorText;
              font-weight: 500;
            }
            .label-small {
              font-family: 'Roboto', sans-serif;
              color: $colorText;
              font-weight: 500;
              &.up {
                color: #00c267;
              }
              &.down {
                color: #ef5350;
              }
            }
          }
        }
      }
      .ReactTable {
        &.table-market {
          border: none;
          overflow: unset;
          .rt-table {
            overflow: unset;
          }

          .rt-thead {
            min-width: unset !important;
            &.-header {
              box-shadow: none;
            }

            .rt-tr {
              overflow: hidden;
            }

            .rt-th {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 32px 5px;
              font-size: 0.875rem;
              line-height: 1rem;
              border: none;

              // &::after {
              //   margin-left: 5px;
              //   content: ' ';
              //   width: 20px;
              //   vertical-align: middle;
              //   color: #717981;
              // }
              &.-sort-asc {
                box-shadow: none;
                &::before {
                  content: url('../icons/ic_sort_down.svg');
                  transform: rotate(180deg);
                  color: #717981;
                }
              }
              &.-sort-desc {
                box-shadow: none;
                &::before {
                  content: url('../icons/ic_sort_down.svg');
                  vertical-align: middle;
                  color: #717981;
                }
              }
              &:first-child {
                justify-content: flex-start;
                padding-left: 24px;
                @media (max-width: 1280px) {
                  box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
                }

                @media (max-width: 1024px) {
                  padding-left: 16px;
                }
                // @media (max-width: 576px) {
                //   width: 100px !important;
                // }
              }
              &:last-child {
                padding-right: 24px;
              }
              .column-markets {
                display: flex;
                align-items: center;
                .icon-collapse {
                  cursor: pointer;
                }
              }
            }
          }
          .rt-tbody {
            overflow: auto;
            min-width: unset !important;

            .rt-tr-group {
              border-bottom: none;

              @media (max-width: 1024px) {
                width: max-content;
              }
              .rt-tr {
                // padding: 17px 0px;
                &:hover {
                  background: darken($color: $colorTrTable, $amount: 5);
                  .rt-td {
                    background: darken($color: $colorTrTable, $amount: 5);
                  }
                }
                &.-odd {
                  background: $colorTrTable;
                  .rt-td {
                    background: $colorTrTable;
                  }
                  &:hover {
                    background: darken($color: $colorTrTable, $amount: 5);
                    .rt-td {
                      background: darken($color: $colorTrTable, $amount: 5);
                    }
                  }
                }
              }
              .rt-td {
                border-right: none;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                &:first-child {
                  padding-left: 24px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  @media (max-width: 1280px) {
                    box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
                  }

                  @media (max-width: 1024px) {
                    padding-left: 6px;
                  }
                  // @media (max-width: 576px) {
                  //   width: 100px !important;
                  // }
                }
                &:last-child {
                  padding-right: 24px;
                }
              }
            }
          }
          .rt-noData {
            display: none;
          }
        }
      }
    }
    // @media only screen and (max-width: 765px) {
    //   .market-box {
    //     margin-top: 30rem;
    //     margin-bottom: 3rem;
    //   }
    // }
  }
  .header {
    min-height: 442px;
    padding-bottom: 130px;
    color: $colorHeaderApp;
    .menu {
      min-height: 100px;
    }
    .introduce {
      margin-top: 36px;
      .title1 {
        color: $colorWhite;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25;
        margin-bottom: 9px;
      }
      .title2 {
        color: $colorFooter;
        font-size: 1.875rem;
        line-height: 3rem;
        font-weight: 700;
        max-width: 50%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media only screen and (max-width: 576px) {
          font-size: 1.5rem;
          line-height: 2.5rem;

          max-width: 80%;
        }
      }
    }
  }
  .footer {
    padding: 1rem 0;
    min-height: 104px;
    background: $colorFooter;

    .footer-container {
      display: flex;
      align-items: center;
      min-height: inherit;

      .footer-container-left {
        display: flex;
        flex-direction: row;
        flex: 1;
      }

      .footer-container-right {
        flex: 1;
        display: flex;
        justify-content: space-between;

        div > a {
          margin: 0 10px;
        }
      }
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      .footer-container {
        .footer-container-left {
          flex-grow: 2;
        }

        .footer-container-right {
          flex-grow: 3;
          justify-content: space-around;

          .right-contact {
            margin-bottom: 1rem;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .footer-container-left {
          flex-grow: 0;
          margin-bottom: 0.5rem;
        }

        .footer-container-right {
          flex-grow: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .right-contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
  .container_app {
    max-width: 1200px;
  }
}

.button {
  border-radius: 0.25rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background: $colorFooter;
  border: none;
  color: $textColor;
  &.btn-supply-dialog {
    background: $colorSupply500;
    &:hover {
      background: darken($color: $colorSupply500, $amount: 5);
    }
  }
  &.btn-borrow-dialog {
    background: $colorBgBorrow;
    &:hover {
      background: darken($color: $colorBgBorrow, $amount: 5);
    }
  }
  &.btn-collateral {
    background: $colorPrimary;
    &:hover {
      background: darken($color: $colorPrimary, $amount: 5);
    }
  }

  &.disabled {
    color: $colorNeutral500 !important;
    background: $colorNeutral300;
    &:hover {
      background: $colorNeutral300;
    }
  }
}

.button-connect {
  font-size: 0.875rem;
  padding: 0.625rem 1.875rem;
  margin: 1.375rem 0;
  transition: 0.2s all ease-in-out;
  &:hover {
    background-color: $colorBlueLight;
  }
}

.nav-logo-text {
  color: $colorWhite;
  font-weight: 700;
  font-style: normal;
  font-size: 1.35rem;
  padding: 1rem 0rem;
  @media (max-width: 576px) {
    font-size: 1.2rem;
  }
}

.text-rifi {
  color: $textColor !important;
  padding-left: 0.6rem;
}

.nav-item-text {
  color: $colorWhite;
  padding: 0.625rem;
  font-size: 0.875rem;
  text-align: center;
  font-weight: 600;
  font-style: normal;
}
.navbar-nav {
  .active {
    .nav-item-text {
      color: $colorFooter;
      font-weight: 900;
    }
  }
}

.nav-hover {
  transition: 0.2s all ease-in-out;

  &:hover {
    font-weight: 600;
    color: $colorFooter;
  }
}

.logo {
  width: 122px;
  height: auto;
  @media (max-width: 576px) {
    width: 80px;
  }
}

.logo-footer {
  width: 3.625rem;
}

.nav {
  padding: 0 !important;
  margin: 0 !important;
  @media (max-width: 992px) {
    align-items: flex-start;
  }
  .nav-toggler {
    border-color: $colorFooter;
    margin-top: 2.8rem;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(4, 215, 162)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
