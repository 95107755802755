.faq-page {
  .faq-breadcrumb {
    display: flex;
    align-items: center;

    &.breadcrumb-header {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    &.breadcrumb-small {
      display: none;

      @media screen and (max-width: 1024px) {
        display: flex;
      }
    }

    .header-page {
      display: flex;
      align-items: center;
      .title {
        margin-left: 15px;
        font-weight: 600;
        font-size: 2.25rem;
        color: $colorNeutral900;

        @media (max-width: 1024px) {
          font-size: 30px;
          line-height: 24px;
        }

        @media (max-width: 576px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    &-topic {
      margin-left: 15px;

      font-weight: 500;
      font-size: 1.5rem;
      color: $colorNeutral700;

      &:before {
        content: '';
        border: solid $colorNeutral700;
        border-width: 0 2px 2px 0;
        border-radius: 1px;
        display: inline-block;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        height: 0px;
        padding: 5px;
        margin: 0px 15px 2px 0;
      }

      @media (max-width: 1024px) {
        font-size: 20px;
        margin-left: 12px;
        &:before {
          padding: 4px;
          margin: 0px 12px 2px 0;
        }
      }

      @media (max-width: 576px) {
        font-size: 16px;
        margin-left: 10px;
        &:before {
          padding: 3px;
          margin: 0px 10px 1px 0;
        }
      }
    }
  }

  .faq-content {
    padding-top: 24px;
    .search-bar {
      margin-top: 21px;
      margin-bottom: 16px;
      background: $colorNeutral100;
      padding: 16px 24px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      .icon-search {
        margin-right: 11px;
        circle {
          stroke: #2963f9;
        }
        path {
          stroke: #2963f9;
        }
      }
      .input-search {
        border: none;
        background: $colorNeutral100;
        width: 100%;
        line-height: 2.25rem;
        &:focus {
          outline: none;
        }
      }
      .clear-input {
        cursor: pointer;
      }
    }

    .list-topics {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      flex-wrap: wrap;

      a {
        border: 1px solid $colorNeutral800;
        border-radius: 4px;
        flex-basis: 30%;

        padding: 16px 0;
        margin-bottom: 24px;
        text-align: center;
        color: $colorNeutral900;

        @media (max-width: 1024px) {
          flex-basis: 47%;
        }

        @media (max-width: 576px) {
          flex-basis: 100%;
          margin-bottom: 16px;
        }
      }
    }

    .list-faq {
      .faq-item {
        box-shadow: 0px 4px 20px rgba(42, 41, 67, 0.12);
        margin: 32px 0px;
        padding: 8px 24px;
        border-radius: 12px;
        background: $colorWhite;
        .question {
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 1.5rem;
          padding-right: 10px;
          padding: 16px 0;
          cursor: pointer;
          position: relative;

          @media (max-width: 768px) {
            font-size: 1rem;
          }

          .question-id {
            color: $colorPrimary;
            margin-right: 8px;
          }
          .question-text {
            color: $colorNeutral900;
            .text-match {
              color: $colorBgSupply;
            }
          }

          &:after {
            content: '';
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translate(-50%, 0);
            border: 1px dotted white;
            width: 3px;
            height: 3px;
            background-color: $colorNeutral800;
            box-sizing: border-box;
            transform: scale(5);
          }

          &.active::after {
            border: none;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
          }
        }
        .answer {
          color: $colorNeutral900;

          max-height: 0;
          overflow: hidden;
          transition: all 0.2s ease-out;

          @media (max-width: 768px) {
            font-size: 14px;
          }

          .text-match {
            color: $colorBgSupply;
          }
        }
      }
    }
  }
}
