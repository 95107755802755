.menu-sidebar {
  overflow: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  height: 100%;
  padding: 0;
  &__branding {
    margin: 40px auto 54px auto;
  }
  .nav-sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__icon {
      width: 24px;
      height: 24px;
    }
    &__item {
      // margin-left: 10px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 27px;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      border-left: 5px solid transparent;
      &.active {
        border-left: 5px solid $colorSideBarActive;
        .nav-sidebar__name {
          color: $colorSideBarActive;
        }
        .nav-sidebar__icon {
          path {
            fill: $colorText;
          }
        }
        .nav-sidebar__icon--stroke {
          path {
            stroke: $colorText;
            fill: none;
          }
        }
      }
      &:hover {
        .nav-sidebar__name {
          color: $colorSideBarActive;
        }
        .nav-sidebar__icon {
          path {
            fill: $colorText;
          }
        }
        .nav-sidebar__icon--stroke {
          path {
            stroke: $colorText;
            fill: none;
          }
        }
      }
    }
    &__name {
      padding-left: 40px;
      color: #9aa0a5;
    }
    &--bottom {
      padding-top: 100px;
    }
  }
  .sidebar-menu-items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .social-media {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;

    &__icon {
      &:hover {
        .nav-sidebar__name {
          color: $colorSideBarActive;
        }
        .nav-sidebar__icon {
          path {
            fill: $colorText;
          }
        }
        .nav-sidebar__icon--stroke {
          path {
            stroke: $colorText;
            fill: none;
          }
        }
      }
    }
  }
  &::-webkit-scrollbar {
    @extend %scrollbar;
  }
  &::-webkit-scrollbar-thumb {
    @extend %scrollbar-thumb;
    background: $colorNeutral500;
  }
  .table-responsive {
    overflow-x: unset;
  }
}
